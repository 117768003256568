export const ATTEMPTS_LIMIT = 20;

export const GENERATED_LOGO_MODEL = 'generated-logo';

export const LOGO_STATUS = {
  GENERATED: 'generated',
  PENDING: 'pending',
  NEW: 'new',
};

export const LOGO_GENERATOR_EVENTS = {
  GENERATED: 'generated-logo.generated',
  ERROR: 'generated-logo.error',
};

export const TRACKING_EVENTS = {
  CREATION: 'logo_creator_button_clicked',
  UPLOAD: 'logo_creator_logo_uploaded',
  ENTRY_POINT_CLICKED: 'logo_creator_entry_point_clicked',
  DOWNLOAD: 'logo_creator_logo_downloaded',
};

export const ALLOWED_ROUTES = {
  CAPITAL: {
    CERTIFICATE: 'capital.certificate',
    KBIS_INFO: 'capital.kbis-info',
    UPLOAD: 'capital.upload',
    REVIEW_INDEX: 'capital.reviewed.index',
  },
};
