export const LOCAL_STORAGE_ORGANIZATION_KEY = 'oauth-challenges-organization';

export const IDENTITY_TYPES = {
  MEMBERSHIP: 'membership',
  USER: 'user',
};

export const OAUTH_SOURCE = {
  REGATE: 'regate',
};
