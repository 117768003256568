export const STATUS = {
  AWAITING: 'awaiting',
  COMPLETED: 'completed',
  PROGRESS: 'in_progress',
} as const;

export const STEPS = {
  COMPLETION: 'completion',
  CREATION: 'creation',
  SHIPMENT: 'shipment',
  VALIDATION: 'validation',
} as const;
