const NRC_STATUS = {
  SCHEDULED: 'scheduled',
  DECLINED: 'declined',
  CANCELED: 'canceled',
  COMPLETED: 'completed',
};

const NRC_LOGIN_PROMOTION_KEY = 'login-feature-nrc';

const PAYER_OPTIONS = {
  YOURSELF: 'yourself',
  SOMEONE_ELSE: 'someoneElse',
};

const NRC_RESULT_STATUS = {
  PROCESSING: 'processing',
  SUCCESS: 'success',
  ERROR: 'error',
  TIMEOUT: 'timeout',
};

const NRC_EVENT_NAMES = {
  SUCCESS: 'nrc_generation.success',
  ERROR: 'nrc_generation.error',
};

const NRC_EVENT_MIN_WAITING_IN_MS = 1000;
const NRC_EVENT_TIMEOUT_IN_MS = 8000;

/**
 * Internal event that doesn't arrive from backend.
 */
const NRC_EVENT_TIMEOUT_REACHED = 'nrc_generation.timeout';

export {
  NRC_STATUS,
  NRC_LOGIN_PROMOTION_KEY,
  PAYER_OPTIONS,
  NRC_RESULT_STATUS,
  NRC_EVENT_NAMES,
  NRC_EVENT_MIN_WAITING_IN_MS,
  NRC_EVENT_TIMEOUT_IN_MS,
  NRC_EVENT_TIMEOUT_REACHED,
};
