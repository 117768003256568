export const HPKE_CARDS_BE_PUBLIC_KEYS_TABLE = {
  local: {
    test_01:
      'BHrHm5Cw4WTRWreMLvPH31ZcigLZR/nIgvn4Z/e+jR2ZMlQFhnYbLTC8LzyrwTAlNfkmc0FRhoqnEx/MMtgjzu4+Le2WS3uwftSOE8w6+54zsSABPjKSfwmSIU1cPW1wWQ==',
    test_02:
      'BBEtt+ojMotO5jjZzXgZ07FYQ7NPzRgRJShYQedXgsQow35ZzirKa0KkLAGHjwkUtK9hdpGcjkVMRvPKNBUwuRprMsYueOpZpAu0HLqH3psicC98/uxx5HuOQLOopKua1Q==',
  },
  staging: {
    test_01:
      'BHrHm5Cw4WTRWreMLvPH31ZcigLZR/nIgvn4Z/e+jR2ZMlQFhnYbLTC8LzyrwTAlNfkmc0FRhoqnEx/MMtgjzu4+Le2WS3uwftSOE8w6+54zsSABPjKSfwmSIU1cPW1wWQ==',
    test_02:
      'BBEtt+ojMotO5jjZzXgZ07FYQ7NPzRgRJShYQedXgsQow35ZzirKa0KkLAGHjwkUtK9hdpGcjkVMRvPKNBUwuRprMsYueOpZpAu0HLqH3psicC98/uxx5HuOQLOopKua1Q==',
  },
  production: {
    prod_01:
      'BCF48AwGEM8fxaV9nKmqWxh3X4ox2aWTPcq/p/ifmJR3KIYw4w3o7pzzXRgoN6ur9mDuYg+Y4chp0bfrmkauEoMXBNdBuveq97fAUaWHxApLvQITgd1HoVvlWagbXk+vXg==',
    prod_02:
      'BH5qjXLWNImnZLaH+r1jN8SNEfQNbFlX4yqh1OlLlz0vzTQC0yMOKd1pjjfSgDK9K+WcciNuP6fdzbPR/SMeYMoxec/YkJSFbqONKoYwD+9Kw+gNUJiX813dRt0W92dxIQ==',
  },
};

// Application supplied information for cards encryption/decryption
export const enum CARDS_INFO {
  CREATE_CARD = 'card.create',
  GET_NUMBERS = 'card.pan_cvv_retrieval',
  GET_PIN = 'card.pin_retrieval',
  SET_PIN = 'card.pin_change',
}
