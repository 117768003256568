export const labelOverTimeAggregations = [
  {
    name: 'display_at_monthly',
    type: 'time',
    data: {
      property: 'display_at',
      interval: 'monthly',
    },
    aggregations: [
      {
        name: 'label_lists_group',
        type: 'term',
        data: {
          property: 'label_lists.id',
        },
        aggregations: [
          {
            name: 'labels_group',
            type: 'term',
            data: {
              property: 'label_lists.labels',
            },
          },
        ],
      },
    ],
  },
];

export const monthlyAggregation = [
  {
    name: 'display_at_monthly',
    type: 'time',
    data: {
      property: 'display_at',
      interval: 'monthly',
    },
  },
];
