export const ON_BEHALF_STATUS = {
  PENDING: 'PENDING',
  COMPLETED: 'COMPLETED',
};

export const POWER_OF_ATTORNEY_STATUS = {
  WAITING: 'WAITING',
  UPLOADED: 'UPLOADED',
  VALIDATED: 'VALIDATED',
};
