import { SCHEDULE_OPERATION_TYPES } from './standing-orders';

export const REQUEST_STATUS_COLORS = {
  declined: 'red',
  canceled: 'red',
  approved: 'green',
  pending: 'orange',
};

export const REQUEST_ORIGINS = {
  tasks: 'tasks',
  reimbursements: 'reimbursements',
  REIMBURSEMENTS_EMPTY_STATE: 'reimbursements_empty_state',
  SUPPLIER_INVOICES: 'supplier-invoices',
  INCOMING_DIRECT_DEBIT: 'incoming_direct_debit',
};

export const REQUEST_EVENT_METHOD = {
  BULK_DETAILS: 'bulk_details',
  QUICK_ACTIONS: 'quick-actions',
};

export const REQUEST_TYPES = {
  TRANSFER: 'transfer',
  MULTI_TRANSFER: 'multi_transfer',
  MILEAGE: 'mileage',
  VIRTUAL_CARD: 'virtual_card',
  FLASH_CARD: 'flash_card',
  EXPENSE_REPORT: 'expense_report',
  MULTI_DIRECT_DEBIT_COLLECTION: 'multi_direct_debit_collection',
};

export let CARDS_BOTTOM_LIMIT = 5;

export let REQUEST_TRANSFER_SCHEDULE_OPERATION_TYPES = [
  'scheduled_later',
  SCHEDULE_OPERATION_TYPES.WEEKLY,
  SCHEDULE_OPERATION_TYPES.MONTHLY,
];

export const STATUS = {
  DECLINED: 'declined',
  CANCELED: 'canceled',
  APPROVED: 'approved',
  PENDING: 'pending',
};

export const APPROVAL_WORKFLOW_STATUS = {
  AWAITS_MY_APPROVAL: 'awaits_my_approval',
  COMPLETED: 'completed',
};

export const MILEAGE_VEHICLE_DETAILS_STORAGE_KEY = 'mileage-vehicle-details';

export const SAME_IBAN_LEGACY_ERROR_MESSAGES = [
  'It looks like you selected twice the same account. Could you verify and try again?',
  'Anscheinend haben Sie zweimal dasselbe Konto ausgewählt. Können Sie dies überprüfen und es erneut versuchen?',
  'Parece que has seleccionado dos veces la misma cuenta. ¿Puedes comprobarlo y volver a probar?',
  'Il semblerait que vous ayez sélectionné deux fois le même compte. Pouvez-vous vérifier et réessayer ?',
  'Sembra che tu abbia selezionato due volte lo stesso conto. Potresti verificare e riprovare?',
];
