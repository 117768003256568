/**
 * Values in SPEND_LIMITS should be a strings or null, because their will be compared with input value and making them string simplify the code (no number <=> string converting)
 */
export const SPEND_LIMITS = {
  UNLIMITED: null,
  VALUE_50: '50',
  VALUE_100: '100',
  VALUE_500: '500',
  VALUE_1000: '1000',
  VALUE_2000: '2000',
  VALUE_5000: '5000',
  VALUE_10000: '10000',
};
