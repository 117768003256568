import { CURRENCIES } from 'qonto/constants/international-out/currency';
import { DEFAULT_MAXIMUM_APPLICABLE_AMOUNT } from 'qonto/constants/international-out/voucher';

export const ELIGIBILITY_STATUS = {
  ELIGIBLE: 'eligible',
  INELIGIBLE: 'ineligible',
};

export const DEFAULT_ELIGIBILITY = {
  status: ELIGIBILITY_STATUS.INELIGIBLE,
  reason: 'unknown',
};

export const DEFAULT_PROMOTIONAL_ELIGIBILITY = {
  status: ELIGIBILITY_STATUS.INELIGIBLE,
  maxAmount: {
    value: DEFAULT_MAXIMUM_APPLICABLE_AMOUNT.toString(),
    currency: CURRENCIES.EUR,
  },
};

export const INELIGIBILITY_REASON = {
  FLEX_KYB_NOT_APPROVED: 'flex_kyb_not_approved',
  KYB_NOT_APPROVED: 'kyb_not_approved',
  UNKNOWN: 'unknown',
};
