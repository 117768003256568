export const ASSOCIATION_LEGAL_CODES = [
  '7323',
  '9224',
  '9220',
  '9221',
  '9230',
  '9222',
  '7321',
  '9150',
  '9210',
  '9223',
  '9300',
  'AC',
];

export const DE_FREELANCERS_LEGAL_CODES = [
  'DE002', // Einzelunternehmer
  'DE210', // Freiberufler
];

export const DE_COMPANY_CREATION_LEGAL_CODES = [
  'DE306', // GmbH in Gründung (i.G.)
  'DE307', // UG in Gründung (i.G.)
];

export const BUSINESS_LEGAL_CODES = [
  'DE002',
  'DE001',
  'IT002',
  'IT001',
  'ATCUST301',
  'NLCUST208',
  'BECUST201',
  'PTCUST183',
  'PTCUST184',
  '1000',
  '1900',
];
