import { BADGE_TYPE } from '@qonto/ui-kit/constants/badge-types';

import { getTrackingNameAndProperties, LAYOUT, TYPES } from 'qonto/constants/empty-states/system';

const name = 'outgoing-direct-debits';
const featureName = 'directDebits';

const EMPTY_LOTTIE_DIRECT_DEBITS = '/lotties/empty-state/no-mandates.json';
const EMPTY_LOTTIE_DIRECT_DEBITS_SEARCH = '/lotties/empty-state/search-no-result.json';

export const getEmptyStateConfig = intl => {
  return {
    name,
    featureName,
    variations: {
      ES_A3: {
        code: 'ES_A3',
        layout: LAYOUT.DISCOVER_PREVIEW,
        tracking: getTrackingNameAndProperties({ name, type: TYPES.ACTIVATE }),
        badgeType: BADGE_TYPE.ACTIVATE,
        badgeText: intl.t('empty-states.system.activate.a3.badge'),
        dummyData: getDummyData(intl),
        variations: [
          {
            inputs: {
              activateAbilities: ['canManageMandates'],
            },
            output: {
              title: intl.t('outgoing-direct-debits.empty-state.a3.title'),
              subtitle: intl.t('outgoing-direct-debits.empty-state.a3.subtitle'),
            },
          },
        ],
      },
      ES_I1: {
        code: 'ES_I1',
        layout: LAYOUT.INFORM,
        tracking: getTrackingNameAndProperties({ name, type: TYPES.INFORM }),
        variations: [
          {
            inputs: {
              customInputs: {
                isSubAccountIsClosed: false,
              },
            },
            output: {
              title: intl.t('outgoing-direct-debits.empty-state.i1.account-selected.title'),
              subtitle: intl.t('outgoing-direct-debits.empty-state.i1.account-selected.subtitle'),
              lottieSrc: EMPTY_LOTTIE_DIRECT_DEBITS,
            },
          },
          {
            inputs: {
              copyAbilities: ['canReadBankAccounts', 'hasMultiAccounts'],
              customInputs: {
                isSubAccountIsClosed: true,
              },
            },
            output: {
              title: intl.t('outgoing-direct-debits.empty-state.i1.closed-account.title'),
              subtitle: intl.t('outgoing-direct-debits.empty-state.i1.closed-account.subtitle'),
              lottieSrc: EMPTY_LOTTIE_DIRECT_DEBITS,
            },
          },
        ],
      },
      ES_I2: {
        code: 'ES_I2',
        layout: LAYOUT.INFORM,
        tracking: getTrackingNameAndProperties({ name, type: TYPES.INFORM }),
        variations: [
          {
            output: {
              title: intl.t('empty-states.system.inform.i2.title'),
              subtitle: intl.t('empty-states.system.inform.i2.subtitle'),
              lottieSrc: EMPTY_LOTTIE_DIRECT_DEBITS_SEARCH,
            },
          },
        ],
      },
    },
  };
};

export const getDummyData = intl => {
  return {
    items: [
      {
        id: 1,
        creditorName: intl.t('outgoing-direct-debits.empty-state.dummy-data.creditor-name-1'),
        successfulDirectDebitsCount: 1,
        isActive: true,
        avatarInfo: {
          smallLogo: `/icon/category/tax-m.svg`,
          mediumLogo: `/icon/category/tax-l.svg`,
        },
        recurrent: true,
        activityTag: 'tax',
        creditorIdentifier: intl.t(
          'outgoing-direct-debits.empty-state.dummy-data.creditor-identifier'
        ),
        umr: intl.t('outgoing-direct-debits.empty-state.dummy-data.umr'),
        bankAccount: {
          name: intl.t('outgoing-direct-debits.empty-state.dummy-data.bank-account-name'),
          iban: intl.t('outgoing-direct-debits.empty-state.dummy-data.iban'),
        },
        attachments: [
          {
            fileName: intl.t('outgoing-direct-debits.empty-state.dummy-data.file-name'),
            fileSize: 1570000,
          },
        ],
      },
      {
        id: 2,
        creditorName: intl.t('outgoing-direct-debits.empty-state.dummy-data.creditor-name-2'),
        successfulDirectDebitsCount: 1,
        isActive: false,
        avatarInfo: {
          smallLogo: `/icon/category/marketing-m.svg`,
          mediumLogo: `/icon/category/marketing-l.svg`,
        },
      },
    ],
  };
};
