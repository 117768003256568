export const EXTERNAL_BANK_RECOMMENDATION_TYPE = {
  SUPPLIER_INVOICE: 'supplier-invoice',
  DIRECT_DEBIT: 'direct-debit',
  CARD: 'card',
};

export const EXTERNAL_BANK_RECOMMENDATION_STATUS = {
  ACTIVE: 'active',
  COMPLETED: 'completed',
  DISMISSED: 'dismissed',
};

export const EXTERNAL_BANK_RECOMMENDATIONS_BUNDLE_STATUS = {
  ACTIVE: 'active',
  DISMISSED: 'dismissed',
};
