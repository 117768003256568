export const ERROR_CODE = {
  COUNTRY_BLOCKED: 'country_blocked',
  INVALID_FIELD: 'invalid_field',
  SEPA_IBAN: 'sepa_iban_with_eur',
  UNSUPPORTED_SWIFT_PAYMENT: 'unsupported_swift_payment',
};

export const ERROR_TRANSLATION_KEY = {
  [ERROR_CODE.COUNTRY_BLOCKED]: 'international-out.dynamic-form.errors.server.country-blocked',
  [ERROR_CODE.INVALID_FIELD]: 'international-out.dynamic-form.errors.server.invalid',
  [ERROR_CODE.SEPA_IBAN]: 'international-out.dynamic-form.errors.server.sepa-iban',
  [ERROR_CODE.UNSUPPORTED_SWIFT_PAYMENT]:
    'international-out.dynamic-form.errors.server.swift-not-supported',
};

export const INVALID_ERROR_POINTER_PREFIX = '/payload/details/';
