import CATEGORIES from 'qonto/constants/categories';
import CURRENCIES from 'qonto/constants/currencies';

const TEMPLATE_VALUES = {
  // categories
  activityTags: CATEGORIES,

  // fx
  currencies: CURRENCIES,

  // documents and attachments
  avatarMaxSize: 5 * 1e6,
  poiMaxSize: 10 * 1e6,
  byLawMaxSize: 15 * 1e6,
  poaMaxSize: 15 * 1e6,
  claimMaxSize: 20 * 1e6,
};

export default TEMPLATE_VALUES;
