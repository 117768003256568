export const QUOTE_STATUSES = {
  APPROVED: 'approved',
  CANCELED: 'canceled',
  PENDING: 'pending_approval',
  COMPLETED: 'approved,canceled',
};

export const QUOTE_STATUS_COLORS = {
  pending_approval: 'orange',
  approved: 'green',
  canceled: 'red',
};
