import { isTesting, macroCondition } from '@embroider/macros';

export const MAX_REMUNERATED_ACCOUNTS = 10;
export const WEB_SOCKET_TIMEOUT = 8000;
export const ESTIMATE_TIMEOUT = 5000;
export const ACCOUNT_ID_LOCAL_STORAGE = 'remunerated-account-id';
export const FUNDING_DEBOUNCE_MS = macroCondition(isTesting()) ? 0 : 1500;
export const DEFAULT_ORIGIN_URL = 'accounts';
export const SAVINGS_DEFAULT_URL = 'savings';
export const QONTO_DEFAULT_LOGO = '/illustrations/app/qonto-logo.svg';

export const ACCOUNT_TYPE = {
  REMUNERATED: 'remunerated',
  CURRENT: 'current',
  WEALTH: 'wealth',
  OTHER: 'other',
};

export const ACCOUNT_STATUS = {
  ACTIVE: 'active',
  WAITING: 'waiting_for_funds',
  CLOSED: 'closed',
  PENDING: 'pending',
};

export const CORE_BANKING_SYSTEMS = {
  CONNECT: 'connect',
  QONTO: 'qonto',
};
