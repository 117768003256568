export const PAGOPA_PAYMENT_EVENTS = {
  AUTHORIZED: 'pagopa_payment.authorized',
  DECLINED: 'pagopa_payment.declined',
};

/**
 * Internal event that doesn't arrive from backend.
 */
export const PAGOPA_PAYMENT_TIMEOUT_REACHED_EVENT = 'pagopa_payment.timeout_reached';

export const PAGOPA_EVENT_TIMEOUT_IN_MS = 10000;

export const PAGOPA_EVENT_MIN_WAITING_IN_MS = 2000;

export const PAGOPA_PAYMENT_RESULT_STATUS = {
  WAITING: 'waiting',
  SUCCESS: 'success',
  ERROR: 'error',
  PROCESSING: 'processing',
};
