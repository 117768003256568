export const GMI_SOLUTION_INSTANCE_STATUSES = {
  ACCOUNT_NOT_READY: 'ACCOUNT_NOT_READY',
  ADD_SUPPLIER_CANCELED: 'ADD_SUPPLIER_CANCELED',
  CANCELLED_VERIFICATION: 'CANCELLED_VERIFICATION',
  CREATED: 'CREATED',
  INPUT_REQUEST: 'INPUT_REQUEST',
  INPUT_REQUEST_FILLED: 'INPUT_REQUEST_FILLED',
  LOGIN_FAILED: 'LOGIN_FAILED',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  NO_DOCUMENTS: 'NO_DOCUMENTS',
  NO_PERMISSION: 'NO_PERMISSION',
  SUPPLIER_ADDED: 'SUPPLIER_ADDED',
  SUPPLIER_UPDATED: 'SUPPLIER_UPDATED',
  TEMP_TECHNICAL_ISSUE: 'TEMP_TECHNICAL_ISSUE',
  TWO_FA_EXPIRED: 'TWO_FA_EXPIRED',
};

export const GMI_STATUSES_SUCCESS_EVENTS = [GMI_SOLUTION_INSTANCE_STATUSES.LOGIN_SUCCESS];

export const GMI_STATUSES_CONNECTING_STATUSES = [
  GMI_SOLUTION_INSTANCE_STATUSES.CREATED,
  GMI_SOLUTION_INSTANCE_STATUSES.INPUT_REQUEST_FILLED,
  GMI_SOLUTION_INSTANCE_STATUSES.SUPPLIER_ADDED,
  GMI_SOLUTION_INSTANCE_STATUSES.SUPPLIER_UPDATED,
  GMI_SOLUTION_INSTANCE_STATUSES.TEMP_TECHNICAL_ISSUE,
];

export const GMI_STATUSES_FAILED_STATUSES = [
  GMI_SOLUTION_INSTANCE_STATUSES.LOGIN_FAILED,
  GMI_SOLUTION_INSTANCE_STATUSES.NO_PERMISSION,
  GMI_SOLUTION_INSTANCE_STATUSES.CANCELLED_VERIFICATION,
  GMI_SOLUTION_INSTANCE_STATUSES.ADD_SUPPLIER_CANCELED,
  GMI_SOLUTION_INSTANCE_STATUSES.CANCELLED_VERIFICATION,
];
