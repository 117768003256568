export const ERROR_CODE = {
  QUOTE_EXPIRED: 'quote_has_expired',
};

export const PROVIDERS = {
  WISE: 'wise',
};

export const STATUSES = {
  INITIATED: 'INITIATED',
};
