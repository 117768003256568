type LegalEntities = {
  [countryCode: string]: {
    solo: string[];
    nonSolo: string[];
  };
};

const LEGAL_ENTITIES: LegalEntities = {
  de: {
    solo: ['Einzelunternehmer', 'e.K.', 'e.K./e.Kfm/e.Kfr', 'Freiberufler'],
    nonSolo: [
      'AG',
      'AG & Co KG',
      'AG & Co oHG',
      'GbR',
      'gGmbH',
      'GMBH',
      'GmbH & Co KG',
      'GmbH & Co oHG',
      'GmbH in Gründung (i.G.)',
      'gUG',
      'KG',
      'KGaA',
      'Kommanditgesellschaft (KG)',
      'oHG',
      'UG',
      'UG & Co. KG',
      'UG in Gründung (i.G.)',
    ],
  },
  es: {
    solo: ['Emprendedor de Responsabilidad Limitada', 'Empresarios Individual (Autónomo)'],
    nonSolo: [
      'SL',
      'Sociedad Anonima Laboral (SAL)',
      'Sociedad Anónima (SA)',
      'Sociedad Colectiva',
      'Sociedad Comanditaria por Acciones',
      'Sociedad Comanditaria Simple',
      'Sociedad de Responsabilidad Limitada Laboral (SLL)',
      'Sociedad de Responsabilidad Limitada (SL/SLR)',
      'Sociedad Limitada de Formación Sucesiva (SLFS)',
      'Sociedad Limitada Nueva Empresa (SLNE)',
      'Sociedad Profesionales (SAP/SAL)',
    ],
  },
  fr: {
    solo: [
      'Agent commercial',
      'Artisan',
      'Associé-gérant',
      'Auto entrepreneur',
      'Auto entreprise/profession libérale',
      '(Autre) personne morale de droit étranger',
      'Autre personne morale de droit privé',
      'Commerçant',
      'EIRL',
      'Empresarios Individual (Autónomo)',
      'Entrepreneur Individuel a Responsabilité Limitée (EIRL)',
      'Entrepreneur individuel (EI)',
      'Entreprise unipersonnelle à responsabilité limitée (EURL)',
      'PROFESSION LIBERALE',
      'Profession libérale',
      'SARLU',
      'SARL unipersonnelle',
      'SARL Unipersonnelle',
    ],
    nonSolo: [
      'AG',
      'Artisan-Commerçant',
      "Association d'avocats à responsabilité professionnelle individuelle",
      'Association déclarée',
      'Association déclarée',
      "Association déclarée (entreprises d'insertion par l'économique)",
      "Association déclarée reconnue d'utilité publique",
      'Association de droit local',
      'Association foncière de remembrement',
      'Association intermédiaire',
      'Association non déclarée',
      'Association syndicale autorisée',
      'Association syndicale libre',
      'Autorité administrative indépendante',
      '(Autre) Collectivité territoriale',
      'Autre établissement public national administratif à compétence territoriale limitée',
      'Autre groupement de droit privé non doté de la personnalité morale',
      'Autre organisme professionnel',
      '(Autre) SA à directoire',
      "(Autre) Service déconcentré de l'Etat à compétence territoriale",
      'Autre société civile',
      'Autres personnes de droit privé inscrites au registre du commerce et des sociétés',
      'Caisse des écoles',
      'Caisse (fédérale) de crédit mutuel',
      'Caisse (locale) de crédit mutuel',
      "Centre communal d'action sociale",
      "Comité central d'entreprise",
      "Comité d'établissement",
      "Communauté d'agglomération",
      'Commune',
      'Congrégation',
      'CUMA',
      'Département',
      'Diverse Autres personnes de droit privé inscrites au registre du commerce et des sociétés',
      "Etablissement d'hospitalisation",
      'Etablissement public local à caractère industriel ou commercial',
      "Etablissement public local d'enseignement",
      'Etablissement public local social et médico-social',
      "Etablissement public national à caractère industriel ou commercial doté d'un comptable public",
      'Etablissement public national à caractère scientifique culturel et professionnel',
      'Etat collectivité ou établissement public étranger',
      'Exploitant agricole',
      'Exploitation agricole à responsabilité limitée',
      'Fondation',
      'GAEC',
      'GEIE',
      'GIE',
      'Groupement agricole foncier',
      "Groupement d'employeurs",
      'Groupement foncier agricole',
      'Groupement foncier rural',
      'Groupement forestier',
      'Indivision avec personne morale',
      'Indivision entre personnes physiques',
      'Instituions',
      'Institution de retraite complémentaire',
      'Micro-entreprise',
      'Ministère',
      'Mutuelle',
      'Ordre professionnel ou assimilé',
      'Organisation internationale',
      'Organisme consulaire',
      'Paroisse hors zone concordataire',
      'Personne physique',
      "Représentation ou agence commerciale d'état ou organisme public étranger immatriculé au RCS",
      'SA',
      "SA à conseil d'administration",
      "SA coopérative à conseil d'administration",
      "SA coopérative artisanale à conseil d'administration",
      "SA coopérative de consommation à conseil d'administration",
      "SA coopérative de production de HLM à conseil d'administration",
      "SA d'économie mixte à conseil d'administration",
      "SA d'économie mixte à directoire",
      'SA de crédit immobilier à directoire',
      'S.A. HLM à directoire',
      "SA nationale à conseil d'administration",
      'SA nationale à directoire',
      'SARL',
      'SARL coopérative',
      'SARL coopérative artisanale',
      'SARL coopérative de transports',
      'SARL coopérative ouvrière de production et de crédit (SCOP)',
      "SARL d'attribution",
      "SARL d'intérêt collectif agricole (SICA)",
      'SARL nationale',
      'SAS',
      'SASU',
      "SA union de sociétés coopératives à conseil d'administration",
      'SCI',
      'SCI de construction vente',
      'SCOP',
      "SCOP à conseil d'administration",
      'SCP',
      "SCP d'avocats",
      "SCP d'avocats aux conseil",
      'SCP de commissaires-priseurs',
      'SCP de dentistes',
      'SCP de médecins',
      'SCP de notaires',
      'SCP de vétérinaires',
      "SCP d'huissiers",
      "SCP d'infirmiers",
      'SCPI',
      "Service central d'un ministère",
      "Service déconcentré de l'Etat à compétence (inter) départementale",
      'Società per azioni (S.p.a.)',
      "Société anonyme mixte d'intérêt collectif agricole (SICA) à conseil d'administration",
      'Société civile coopérative de construction',
      "Société civile coopérative (d'intérêt) maritime",
      "Société civile d'attribution",
      'Société civile de moyens',
      "Société civile d'exploitation agricole",
      'Société civile foncière',
      'Société coopérative agricole',
      'Société créée de fait avec personne morale',
      'Société créée de fait entre personnes physiques',
      'Société de Participations Financières de Profession Libérale Société à responsabilité limitée (SPFPL SARL)',
      'Société de Participations Financières de Profession Libérale Société par actions simplifiée (SPFPL SAS)',
      "Société d'exercice libéral à forme anonyme à conseil d'administration",
      "Société d'exercice libéral à forme anonyme à directoire",
      "Société d'exercice libéral à responsabilité limitée",
      "Société d'exercice libéral par action simplifiée",
      "Société d'investissement à capital variable (SICAV) à conseil d'administration",
      'Société en commandite par actions',
      'Société en commandite simple',
      'Société en nom collectif',
      'Société en participation avec personne morale',
      'Société en participation de professions libérales',
      'Société en participation entre personnes physiques',
      'Société étrangère immatriculée au RCS',
      'Société étrangère non immatriculée au RCS',
      'Société européenne',
      'Sociétés Interprofessionnelles de Soins Ambulatoires',
      'Syndicat de copropriété',
      'Syndicat de salariés',
      'Syndicat intercommunal à vocation multiple (SIVOM)',
      'Syndicat patronal',
      'Union de sociétés coopératives agricoles',
    ],
  },
  it: {
    solo: [
      'Imprenditore individuale',
      'Impresa individuale',
      'IMPRESA INDIVIDUALE',
      'Libero professionista',
      'Libero professionista / Lavoratore autonomo',
      'Libero Professionista/Lavoratore autonomo',
      'Persona fisica',
    ],
    nonSolo: [
      'Altre forme',
      'Associazione',
      'ASSOCIAZIONE',
      'Associazione impresa',
      'Consorzio',
      'Consorzio con attività esterna',
      'Contratto di rete dotato di soggettività giuridica',
      'Cooperativa sociale',
      'COOPERATIVA SOCIALE',
      'Ente',
      'Ente diritto pubblico',
      'Ente ecclesiastico',
      'Ente ecclesiastico civilmente riconosciuto',
      'Ente morale',
      'Fondazione',
      'Gruppo europeo di interesse economico',
      'SAS',
      "SOCIETA' A RESPONSABILITA' LIMITATA",
      'Società a responsabilità limitata',
      "SOCIETA' A RESPONSABILITA' LIMITATA CON UNICO SOCIO",
      'Società a responsabilità limitata con unico socio',
      'Società a responsabilità limitata con un socio unico',
      "SOCIETA' A RESPONSABILITA' LIMITATA SEMPLIFICATA",
      'Società a responsabilità limitata semplificata',
      'Società a responsabilità limitata semplificata (S.r.l.s.)',
      'Società a responsabilità limitata semplificata (S.r.l.s)',
      'Società a responsabilità limitata (S.r.l.)',
      'Società consortile a responsabilità limitata',
      'Società consortile cooperativa a responsabilità limitata',
      'Società consortile in accomandita semplice',
      'Società consortile per azioni',
      "SOCIETA' COOPERATIVA",
      'Società cooperativa',
      "SOCIETA' COOPERATIVA A RESPONSABILITA LIMITATA",
      'Società cooperativa a responsabilita limitata',
      'Società cooperativa consortile',
      'Società costituita in base a leggi di altro stato',
      'Società di mutuo soccorso',
      'Società europea',
      'Società in accomandita per azioni',
      'Società in accomandita per azioni (S.a.p.a.)',
      "SOCIETA' IN ACCOMANDITA SEMPLICE",
      'Società in accomandita semplice',
      'Società in accomandita semplice (S.a.s.)',
      'Società in nome collettivo',
      'Società in nome collettivo (S.n.c.)',
      "SOCIETA' PER AZIONI",
      'Società per azioni',
      'Società  per azioni con socio unico',
      'Società per azioni (S.p.a.)',
      'Società semplice',
      'Società semplice (S.s.)',
      'Soggetto estero',
      'SOGGETTO ESTERO',
      'Studio associato e società di professionisti',
    ],
  },
};

const soloEntities = [
  ...(LEGAL_ENTITIES['de']?.solo ?? []),
  ...(LEGAL_ENTITIES['es']?.solo ?? []),
  ...(LEGAL_ENTITIES['fr']?.solo ?? []),
  ...(LEGAL_ENTITIES['it']?.solo ?? []),
];

const nonSoloEntities = [
  ...(LEGAL_ENTITIES['de']?.nonSolo ?? []),
  ...(LEGAL_ENTITIES['es']?.nonSolo ?? []),
  ...(LEGAL_ENTITIES['fr']?.nonSolo ?? []),
  ...(LEGAL_ENTITIES['it']?.nonSolo ?? []),
];

export function isSoloEntity(entity: string): boolean {
  return soloEntities.includes(entity);
}

export function isNonSoloEntity(entity: string): boolean {
  return nonSoloEntities.includes(entity);
}
