export const UBO_CONNECTION_TO_ORGANIZATION_KEYS = [
  'managing_partner',
  'silent_partner',
  'shareholders_family_member',
  'other',
] as const;

export type UboConnectionToOrganizationKey = (typeof UBO_CONNECTION_TO_ORGANIZATION_KEYS)[number];

export const UBO_CONNECTION_TO_ORGANIZATION_TRANSLATION_PATHS: Record<
  UboConnectionToOrganizationKey,
  string
> = {
  managing_partner:
    'organizations.profile.owner-form.ubo-connection-dropdown.option.managing-partner',
  silent_partner: 'organizations.profile.owner-form.ubo-connection-dropdown.option.silent-partner',
  shareholders_family_member:
    'organizations.profile.owner-form.ubo-connection-dropdown.option.family-member',
  other: 'organizations.profile.owner-form.ubo-connection-dropdown.option.other',
};
