export const TEMPLATES = {
  pennylane: {
    mapping: {
      Dénomination: 'name',
      "Liste d'e-mails": 'email',
      Adresse: 'billing_address.street_address',
      'Code postal': 'billing_address.zip_code',
      Ville: 'billing_address.city',
      Pays: 'billing_address.country_code',
      'Adresse de livraison': 'delivery_address.street_address',
      'Code postal de livraison': 'delivery_address.zip_code',
      'Ville de livraison': 'delivery_address.city',
      'Pays de livraison': 'delivery_address.country_code',
      Siren: 'tin_number',
      'Numéro de TVA': 'vat_number',
      Prénom: 'first_name',
      Nom: 'last_name',
      'Type de client': 'kind',
    },
    colsToHide: ['Identifiant client'],
  },
  meg: {
    mapping: {
      'Raison sociale': 'name',
      Email: 'email',
      'Adresse principale Adresse 1': 'billing_address.street_address',
      'Adresse principale Code postal': 'billing_address.zip_code',
      'Adresse principale Ville': 'billing_address.city',
      'Adresse principale Pays': 'billing_address.country_code',
      'N° Siret': 'tin_number',
      'Informations TVA N° TVA Intra': 'vat_number',
      Prenom: 'first_name',
      Nom: 'last_name',
      'Forme juridique': 'kind',
    },
  },
  henrii: {
    mapping: {
      'Raison Sociale': 'name',
      Mail: 'email',
      Adresse: 'billing_address.street_address',
      'Code Postal': 'billing_address.zip_code',
      Ville: 'billing_address.city',
      Nom: 'last_name',
    },
  },
  fatture_in_cloud: {
    mapping: {
      Nome: 'name',
      'E-mail': 'email',
      Indirizzo: 'billing_address.street_address',
      CAP: 'billing_address.zip_code',
      Comune: 'billing_address.city',
      Provincia: 'billing_address.province_code',
      Paese: 'billing_address.country_code',
      'Codice Fiscale': 'tin_number',
      'P.IVA/TAX ID': 'vat_number',
      'Codice SDI': 'recipient_code',
    },
  },
  zoho_invoices: {
    mapping: {
      'Customer Name': 'name',
      EmailID: 'email',
      'Billing Address': 'billing_address.street_address',
      'Billing Code': 'billing_address.zip_code',
      'Billing City': 'billing_address.city',
      'Billing Country': 'billing_address.province_code',
      'Shipping Address': 'delivery_address.street_address',
      'Shipping Code': 'delivery_address.zip_code',
      'Shipping City': 'delivery_address.city',
      'Shipping Country': 'delivery_address.province_code',
      'Currency Code': 'currency',
      'First Name': 'first_name',
      'Last Name': 'last_name',
      'Contact Type': 'kind',
    },
  },
  holded: {
    mapping: {
      Name: 'name',
      Email: 'email',
      Address: 'billing_address.street_address',
      'Postal code': 'billing_address.zip_code',
      City: 'billing_address.city',
      Province: 'billing_address.province_code',
      'Country/Country Code': 'billing_address.country_code',
      Language: 'locale',
      Currency: 'currency',
      'Contact type': 'kind',
    },
    colsToHide: ['ID'],
  },
};
