const SOLUTION_INSTANCE_STATUS = {
  PENDING: 'pending',
  CREATED: 'created',
  FAILED: 'failed',
  MARKED_FOR_ENABLEMENT: 'marked_for_enablement',
  MARKED_FOR_DELETION: 'marked_for_deletion',
  ENABLED: 'enabled',
};

export default SOLUTION_INSTANCE_STATUS;
