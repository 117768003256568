import ENV from 'qonto/config/environment';

const { frontendUrlMode } = ENV;

const prismicTable = {
  development: {
    prismicApi: 'https://qonto-replica-prod.cdn.prismic.io/api/v2',
    prismicToken:
      'MC5YLTJjeVJVQUFHak5ocDB3.Sivvv71777-977-977-977-977-9Ue-_ve-_ve-_ve-_ve-_vTPvv71i77-9Xe-_ve-_ve-_vT3vv73vv70SNu-_ve-_vX7vv70',
    repoName: 'qonto-replica-prod',
  },
  staging: {
    prismicApi: 'https://qonto-replica-prod.cdn.prismic.io/api/v2',
    prismicToken:
      'MC5YLTJjeVJVQUFHak5ocDB3.Sivvv71777-977-977-977-977-9Ue-_ve-_ve-_ve-_ve-_vTPvv71i77-9Xe-_ve-_ve-_vT3vv73vv70SNu-_ve-_vX7vv70',
    repoName: 'qonto-replica-prod',
  },
  production: {
    prismicApi: 'https://qonto.cdn.prismic.io/api/v2',
    prismicToken:
      'MC5XNHo0VlNBQUFCMEE5M0JB.X--_vU5k77-977-9L--_vRVx77-977-977-9Qu-_vXDvv70zaiAjO--_vTkoU--_ve-_vWPvv70G77-9',
    repoName: 'qonto',
  },
};

export const { prismicApi, prismicToken, repoName } = prismicTable[frontendUrlMode];
