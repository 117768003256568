import { dasherize } from '@ember/string';

export const TYPES = {
  ACTIVATE: 'ES_A',
  UPSELL: 'ES_U',
  TRY: 'ES_T',
  INFORM: 'ES_I',
};

// Following are the mappings of ES codes to layout types:
// • A3, U1, T1 → `discover-preview`
// • A2 → `discover-plain`
// • I3 → `discover-preview`
// • I1, I2 → `inform`

export const LAYOUT = {
  DISCOVER_PLAIN: 'discover-plain',
  DISCOVER_PREVIEW: 'discover-preview',
  INFORM: 'inform',
};

export const CODES = {
  ES_A1: 'ES_A1',
  ES_A2: 'ES_A2',
  ES_A3: 'ES_A3',
  ES_U1: 'ES_U1',
  ES_U2: 'ES_U2',
  ES_T1: 'ES_T1',
  ES_T2: 'ES_T2',
  ES_I1: 'ES_I1',
  ES_I2: 'ES_I2',
  ES_I3: 'ES_I3',
};

export const PERMISSIONS = {
  ACTIVATE: ['create', 'request', 'review', 'upload', 'update'],
  UPSELL: ['create', 'request', 'review', 'upload', 'update', 'read'],
  TRY: ['create', 'request', 'review', 'upload', 'update', 'read'],
};

const CLICK_TRACKING_TYPE = {
  [TYPES.ACTIVATE]: 'create',
  [TYPES.INFORM]: 'create',
  [TYPES.UPSELL]: 'upsell',
  [TYPES.TRY]: 'try',
};

const SHOW_TRACKING_TYPE = {
  [TYPES.ACTIVATE]: 'activate',
  [TYPES.UPSELL]: 'upsell',
  [TYPES.TRY]: 'try',
  [TYPES.INFORM]: 'inform',
};

export const TRACKING_ORIGINS = {
  PRIMARY: 'empty_state_primary',
  SECONDARY: 'empty_state_secondary',
  HEADER: 'page_header',
};

const SHOW_TRACKING = 'empty-state_shown';

export const getTrackingNameAndProperties =
  ({ type, name, customProps = {} }) =>
  ({ isClickEvent, isEmptyState, origin }) => {
    name = name ? dasherize(name) : name;
    if (type && name) {
      if (isClickEvent) {
        return {
          name: `${name}_cta_clicked`,
          properties: {
            cta_type: CLICK_TRACKING_TYPE[type],
            empty_state: isEmptyState,
            origin,
            ...customProps,
          },
        };
      } else {
        return {
          name: SHOW_TRACKING,
          properties: {
            'empty-state_type': SHOW_TRACKING_TYPE[type],
            'empty-state_feature': name,
            ...customProps,
          },
        };
      }
    }
  };
