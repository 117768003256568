export const TEMPLATES = {
  pennylane: {
    mapping: {
      'Dénomination du produit': 'title',
      Description: 'description',
      'Prix HT (€)': 'unit_price.value',
      "Type d'unité": 'unit',
      'Taux de TVA': 'vat_rate',
      'Référence produit': 'internal_note',
    },
    colsToHide: [],
  },
  meg: {
    mapping: {
      Libellé: 'title',
      Description: 'description',
      'Prix de vente HT': 'unit_price.value',
      Unité: 'unit',
      TVA: 'vat_rate',
      Type: 'type',
    },
  },
  fatture_in_cloud: {
    mapping: {
      'Nome prodotto/servizio': 'title',
      Descrizione: 'description',
      'Prezzo netto': 'unit_price.value',
      'U.D.M.': 'unit',
      'Aliquota IVA': 'vat_rate',
      Categoria: 'type',
      Extra: 'internal_note',
    },
  },
  zoho_invoices: {
    mapping: {
      'Item Name': 'title',
      Description: 'description',
      Rate: 'unit_price.value',
      'Usage unit': 'unit',
      'Purchase Tax Percentage': 'vat_rate',
      'product type': 'type',
    },
  },
  holded: {
    mapping: {
      Name: 'title',
      Description: 'description',
      Cost: 'unit_price.value',
      'Cost value': 'unit_price.currency',
      VAT: 'vat_rate',
    },
  },
};
