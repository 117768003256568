const COMPONENT_NAMESPACE = 'transfers/international-out/dynamic-form';

const TRIGGER_SELECT_COMPONENTS = Object.freeze({
  COUNTRY: `${COMPONENT_NAMESPACE}/select-field/country-trigger`,
  DEFAULT: `${COMPONENT_NAMESPACE}/select-field/default-trigger`,
});

const ALLOWED_FIELD_TYPES = Object.freeze({
  DATE: 'date',
  RADIO: 'radio',
  SELECT: 'select',
  TEXT: 'text',
});

const VALIDATION_TRIGGER_TYPES = Object.freeze({
  BLUR: 'blur',
  INPUT: 'input',
});

export {
  ALLOWED_FIELD_TYPES,
  COMPONENT_NAMESPACE,
  TRIGGER_SELECT_COMPONENTS,
  VALIDATION_TRIGGER_TYPES,
};
