import { BADGE_TYPE } from '@qonto/ui-kit/constants/badge-types';
import dayjs from 'dayjs';

import {
  CODES,
  getTrackingNameAndProperties,
  LAYOUT,
  TYPES,
} from 'qonto/constants/empty-states/system';
import { QUOTE_STATUSES } from 'qonto/constants/quotes';

const TAB_NAMES = {
  COMPLETED: 'completed',
  PENDING: 'pending',
};

const EMPTY_LOTTIE_SRC = '/lotties/receivable-invoices/empty-state-draft.json';
const EMPTY_RESULTS = '/lotties/empty-state/search-no-result.json';
const featureName = 'quotes';
const name = 'quotes';

export const getEmptyStateConfig = (intl, { ctaCallback }) => {
  let dummyData = getDummyData(intl);

  return {
    name,
    featureName,
    variations: {
      ES_A3: {
        code: CODES.ES_A3,
        layout: LAYOUT.DISCOVER_PREVIEW,
        dummyData,
        badgeType: BADGE_TYPE.ACTIVATE,
        badgeText: intl.t('empty-states.system.activate.a3.badge'),
        ctaText: intl.t('receivable-invoices.quotes-list.a3.cta'),
        onClick: ctaCallback,
        tracking: getTrackingNameAndProperties({ name, type: TYPES.ACTIVATE }),
        variations: [
          {
            inputs: {},
            output: {
              title: intl.t('receivable-invoices.quotes-list.empty-state.a3.title'),
              subtitle: intl.t('receivable-invoices.quotes-list.empty-state.a3.unlimited.subtitle'),
            },
          },
        ],
      },
      ES_I1: {
        code: CODES.ES_I1,
        layout: LAYOUT.INFORM,
        lottieSrc: EMPTY_LOTTIE_SRC,
        tracking: getTrackingNameAndProperties({ name, type: TYPES.INFORM }),
        variations: [
          {
            inputs: {
              customInputs: {
                tab: TAB_NAMES.COMPLETED,
              },
            },
            output: {
              title: intl.t('receivable-invoices.quotes-list.tabs.completed.i1.title'),
              subtitle: intl.t('receivable-invoices.quotes-list.tabs.completed.i1.body'),
            },
          },
          {
            inputs: {
              customInputs: {
                tab: TAB_NAMES.PENDING,
              },
            },
            output: {
              title: intl.t('receivable-invoices.quotes-list.tabs.pending.i1.title'),
              subtitle: intl.t('receivable-invoices.quotes-list.tabs.pending.i1.body'),
            },
          },
        ],
      },

      ES_I2: {
        code: CODES.ES_I2,
        layout: LAYOUT.INFORM,
        lottieSrc: EMPTY_RESULTS,
        tracking: getTrackingNameAndProperties({ name, type: TYPES.INFORM }),
        variations: [
          {
            output: {
              title: intl.t('empty-states.system.inform.i2.title'),
              subtitle: intl.t('empty-states.system.inform.i2.subtitle'),
            },
          },
        ],
      },
    },
  };
};

export const getDummyData = intl => {
  let hasMany = () => {
    return {
      ids: () => [],
    };
  };

  let currentDate = dayjs();
  let issueDate = currentDate.subtract(15, 'day').format('YYYY-MM-DD');
  let expiryDate = currentDate.add(15, 'day').format('YYYY-MM-DD');

  return {
    status: QUOTE_STATUSES.PENDING,
    numberOfPendingQuotes: 32,
    columnsToShow: {
      customer: true,
      issueDate: true,
      expiryDate: true,
      amountDue: true,
    },
    items: [
      {
        customerSnapshot: {
          name: intl.t('receivable-invoices.quotes-list.empty-state.dummy-data.row-1.client'),
        },
        number: intl.t('receivable-invoices.quotes-list.empty-state.dummy-data.row-1.number'),
        issueDate,
        expiryDate,
        amountDue: intl.t('receivable-invoices.quotes-list.empty-state.dummy-data.row-1.amount'),
        hasMany,
      },
      {
        customerSnapshot: {
          name: intl.t('receivable-invoices.quotes-list.empty-state.dummy-data.row-2.client'),
        },
        number: intl.t('receivable-invoices.quotes-list.empty-state.dummy-data.row-2.number'),
        issueDate,
        expiryDate,
        amountDue: intl.t('receivable-invoices.quotes-list.empty-state.dummy-data.row-2.amount'),
        hasMany,
      },
      {
        customerSnapshot: {
          name: intl.t('receivable-invoices.quotes-list.empty-state.dummy-data.row-3.client'),
        },
        number: intl.t('receivable-invoices.quotes-list.empty-state.dummy-data.row-3.number'),
        issueDate,
        expiryDate,
        amountDue: intl.t('receivable-invoices.quotes-list.empty-state.dummy-data.row-3.amount'),
        hasMany,
      },
      {
        customerSnapshot: {
          name: intl.t('receivable-invoices.quotes-list.empty-state.dummy-data.row-4.client'),
        },
        number: intl.t('receivable-invoices.quotes-list.empty-state.dummy-data.row-4.number'),
        issueDate,
        expiryDate,
        amountDue: intl.t('receivable-invoices.quotes-list.empty-state.dummy-data.row-4.amount'),
        hasMany,
      },
    ],
  };
};
