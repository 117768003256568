export const LEGAL_FORMS_SHORTCUTS = {
  SAS: 'SAS',
  SASU: 'SASU',
  SARL: 'SARL',
  'Entrepreneur Individuel a Responsabilité Limitée (EIRL)': 'EIRL',
  Artisan: 'Artisan',
  Commerçant: 'Commerçant',
  'Profession libérale': 'Profession libérale',
  'Società a responsabilità limitata': 'SRL',
  'SARL unipersonnelle': 'SARL unipersonnelle',
  SCI: 'SCI',
  'Società a responsabilità limitata semplificata': 'SRLS',
  'Sociedad de Responsabilidad Limitada (SL/SLR)': 'SRL',
  'Association déclarée': 'Association déclarée',
  'Impresa individuale': 'Impresa individuale',
  'Società a responsabilità limitata (S.r.l.)': 'SRL',
  GmbH: 'GmbH',
  Einzelunternehmer: 'Einzelunternehmer',
  'Imprenditore individuale': 'Impresa individuale',
  'Società a responsabilità limitata semplificata (S.r.l.s)': 'SRLS',
  'Libero Professionista/Lavoratore autonomo': 'Libero Professionista/Lavoratore autonomo',
  'Entreprise unipersonnelle à responsabilité limitée (EURL)': 'EURL',
  'Personne physique': 'Personne physique',
  'Empresarios Individual (Autónomo)': 'EI',
  'Libero Professionista': 'Libero Professionista',
  'Lavoratore autonomo': 'Lavoratore autonomo',
  UG: 'UG',
  'Autre société civile': 'Autre société civile',
  'Società in accomandita semplice': 'SAS',
  'Artisan-Commerçant': 'Artisan',
  'GmbH in Gründung (i.G.)': 'Gmbh (i.G.)',
  'UG in Gründung (i.G.)': 'UG (i.G.)',
  'Società a responsabilità limitata con unico socio': 'SRL Unipersonal',
  'Agent commercial': 'Agent commercial',
  'Società in nome collettivo': 'SNC',
  'Société étrangère immatriculée au RCS': 'Société étrangère immatriculée au RCS',
  'Società in accomandita semplice (S.a.s.)': 'SAS',
  'Società cooperativa': 'COOP',
  'Exploitant agricole': 'Exploitant agricole',
  "Société d'exercice libéral à responsabilité limitée": 'SELARL',
  'Société en nom collectif': 'SNC',
  'Società a responsabilità limitata con un socio unico': 'SRL Unipersonal',
  Associazione: 'Associazione',
  "SA à conseil d'administration": "SA à conseil d'administration",
  Freiberufler: 'Freiberufler',
  'Sociedad Anónima (SA)': 'SA',
  'Società semplice': 'SS',
  'Società in nome collettivo (S.n.c.)': 'SNC',
  'SCI de construction vente': 'SCI de construction vente',
  'e.K./e.Kfm/e.Kfr': 'e.K./e.Kfm/e.Kfr',
  'Società per azioni': 'SPA',
  'Gesellschaft mit beschränkter Haftung & Compagnie Kommanditgesellschaft (GmbH & Co KG)':
    'GmbH & Co KG',
  'Gemeinnützige Gesellschaft mit beschränkter Haftung (gGmbH)': 'gGmbH',
  'Sociedad Colectiva': 'SC/SRC',
  'Exploitation agricole à responsabilité limitée':
    'Exploitation agricole à responsabilité limitée',
  'Société étrangère non immatriculée au RCS': 'Société étrangère non immatriculée au RCS',
  'e.K.': 'e.K.',
  'Emprendedor de Responsabilidad Limitada': 'ERL',
  AG: 'AG',
  'Società semplice (S.s.)': 'SS',
  'Studio associato e società di professionisti': 'Studio associato e società di professionisti',
  "Société civile d'exploitation agricole": "Société civile d'exploitation agricole",
  'Sociedad Limitada Nueva Empresa (SLNE)': 'SLNE',
  'Società per azioni (S.p.a.)': 'SPA',
  '(Autre) personne morale de droit étranger': '(Autre) personne morale de droit étranger',
  GIE: 'GIE',
  '': '',
  EURL: 'EURL',
  'Société civile de moyens': 'Société civile de moyens',
  "Société d'exercice libéral par action simplifiée":
    "Société d'exercice libéral par action simplifiée",
  'Libero professionista': 'Libero professionista',
  '-': '-',
  'IMPRESA INDIVIDUALE': 'Impresa individuale',
  'Sociedad de Responsabilidad Limitada Laboral (SLL)': 'SLL',
  'Sociedad Profesionales (SAP/SAL)': 'SAP/SAL',
  'Indivision entre personnes physiques': 'Indivision entre personnes physiques',
  'Syndicat de copropriété': 'Syndicat de copropriété',
  'Association de droit local': 'Association de droit local',
  'Kommanditgesellschaft (KG)': 'KG',
  'Soggetto estero': 'Soggetto estero',
  GAEC: 'GAEC',
  Consorzio: 'Consorzio',
  'Società costituita in base a leggi di altro stato':
    'Società costituita in base a leggi di altro stato',
  'Groupement foncier agricole': 'Groupement foncier agricole',
  oHG: 'oHG',
  'Sociedad Limitada de Formación Sucesiva (SLFS)': 'SLFS',
  'Sociedad Comanditaria Simple': 'S. en C.S.',
  'Sociedad Anonima Laboral (SAL)': 'SAL',
  KG: 'KG',
  'Società consortile a responsabilità limitata': 'Società consortile a responsabilità limitata',
  'UG & Co. KG': 'UG & Co. KG',
  'Société créée de fait entre personnes physiques':
    'Société créée de fait entre personnes physiques',
  'AG & Co KG': 'AG & Co KG',
  'Società cooperativa a responsabilita limitata': 'Società cooperativa a responsabilita limitata',
  '(Autre) SA à directoire': '(Autre) SA à directoire',
  "Association déclarée reconnue d'utilité publique":
    "Association déclarée reconnue d'utilité publique",
  'Cooperativa sociale': 'Cooperativa sociale',
  Fondation: 'Fondation',
  Commune: 'Commune',
  KGaA: 'KGaA',
  'Sociedad Comanditaria por Acciones': 'S. COM. P.A.',
  'Société en commandite simple': 'Société en commandite simple',
  "Association d'avocats à responsabilité professionnelle individuelle": 'AARPI',
  'Autre groupement de droit privé non doté de la personnalité morale':
    'Autre groupement de droit privé non doté de la personnalité morale',
  'Groupement forestier': 'Groupement forestier',
  "SOCIETA' A RESPONSABILITA' LIMITATA SEMPLIFICATA": 'SRLS',
  'AG & Co oHG': 'AG & Co oHG',
  'SARL coopérative': 'SARL coopérative',
  'GmbH & Co oHG': 'GmbH & Co oHG',
  "SOCIETA' A RESPONSABILITA' LIMITATA": 'SRL',
  "SA coopérative à conseil d'administration": "SA coopérative à conseil d'administration",
  'SARL nationale': 'SARL nationale',
  'Associé-gérant': 'Associé-gérant',
  'Société de Participations Financières de Profession Libérale Société par actions simplifiée (SPFPL SAS)':
    'SPFPL SAS',
  'Association syndicale libre': 'Association syndicale libre',
  'Autre personne morale de droit privé': 'Autre personne morale de droit privé',
  'Organisme consulaire': 'Organisme consulaire',
  'SARL coopérative ouvrière de production et de crédit (SCOP)': 'SCOP',
  SARLU: 'SARLU',
  'Société en commandite par actions': 'Société en commandite par actions',
  'Société en participation entre personnes physiques':
    'Société en participation entre personnes physiques',
  'Association non déclarée': 'Association non déclarée',
  "SA d'économie mixte à conseil d'administration":
    "SA d'économie mixte à conseil d'administration",
  "Société civile d'attribution": "Société civile d'attribution",
  'Société européenne': 'Société européenne',
  'Contratto di rete dotato di soggettività giuridica':
    'Contratto di rete dotato di soggettività giuridica',
  'Etat collectivité ou établissement public étranger':
    'Etat collectivité ou établissement public étranger',
  'Société de Participations Financières de Profession Libérale Société à responsabilité limitée (SPFPL SARL)':
    'SPFPL SARL',
  'Syndicat patronal': 'Syndicat patronal',
  'Consorzio con attività esterna': 'Consorzio con attività esterna',
  "Groupement d'employeurs": "Groupement d'employeurs",
  SCP: 'SCP',
  "Association déclarée (entreprises d'insertion par l'économique)":
    "Association déclarée (entreprises d'insertion par l'économique)",
  "Comité central d'entreprise": "Comité central d'entreprise",
  CUMA: 'CUMA',
  "Etablissement public local d'enseignement": "Etablissement public local d'enseignement",
  'Gruppo europeo di interesse economico': 'Gruppo europeo di interesse economico',
  'Società in accomandita per azioni': 'SAA',
  'Syndicat de salariés': 'Syndicat de salariés',
  Mutuelle: 'Mutuelle',
  'SARL Unipersonnelle': 'SARL Unipersonnelle',
  'SCP de vétérinaires': 'SCP de vétérinaires',
  "Service déconcentré de l'Etat à compétence (inter) départementale":
    "Service déconcentré de l'Etat à compétence (inter) départementale",
  'Società di mutuo soccorso': 'Società di mutuo soccorso',
  'Società in accomandita per azioni (S.a.p.a.)': 'SAPA',
  'Société en participation avec personne morale': 'Société en participation avec personne morale',
  'Association intermédiaire': 'Association intermédiaire',
  'Association syndicale autorisée': 'Association syndicale autorisée',
  '(Autre) Collectivité territoriale': 'Collectivité territoriale',
  EIRL: 'EIRL',
  'Società cooperativa consortile': 'Società cooperativa consortile',
  'Società  per azioni con socio unico': 'Società  per azioni con socio unico',
  'Altre forme': 'Altre forme',
  'Autorité administrative indépendante': 'Autorité administrative indépendante',
  "Comité d'établissement": "Comité d'établissement",
  Ente: 'Ente',
  Fondazione: 'Fondazione',
  'SARL coopérative artisanale': 'SARL coopérative artisanale',
  'Société civile foncière': 'Société civile foncière',
  'Société coopérative agricole': 'Société coopérative agricole',
  'Caisse (locale) de crédit mutuel': 'Caisse (locale) de crédit mutuel',
  "Etablissement d'hospitalisation": "Etablissement d'hospitalisation",
  'Etablissement public local à caractère industriel ou commercial':
    'Etablissement public local à caractère industriel ou commercial',
  'Groupement foncier rural': 'Groupement foncier rural',
  'Paroisse hors zone concordataire': 'Paroisse hors zone concordataire',
  "SARL d'attribution": "SARL d'attribution",
  "SCP d'avocats": "SCP d'avocats",
  'Società a responsabilità limitata semplificata (S.r.l.s.)': 'SRLS',
  "SOCIETA' COOPERATIVA": 'COOP',
  'Société créée de fait avec personne morale': 'Société créée de fait avec personne morale',
  "Société d'exercice libéral à forme anonyme à conseil d'administration":
    "Société d'exercice libéral à forme anonyme à conseil d'administration",
  'Société en participation de professions libérales':
    'Société en participation de professions libérales',
  'Association foncière de remembrement': 'Association foncière de remembrement',
  'Associazione impresa': 'Associazione impresa',
  'Autre établissement public national administratif à compétence territoriale limitée':
    'Autre établissement public national administratif à compétence territoriale limitée',
  Congrégation: 'Congrégation',
  Département: 'Département',
  'Diverse Autres personnes de droit privé inscrites au registre du commerce et des sociétés':
    'Diverse Autres personnes de droit privé inscrites au registre du commerce et des sociétés',
  'Etablissement public local social et médico-social':
    'Etablissement public local social et médico-social',
  'Etablissement public national à caractère scientifique culturel et professionnel':
    'Etablissement public national à caractère scientifique culturel et professionnel',
  'Persona fisica': 'Persona fisica',
  'Régime général de la sécurité sociale': 'Régime général de la sécurité sociale',
  "SA coopérative artisanale à conseil d'administration":
    "SA coopérative artisanale à conseil d'administration",
  "SA coopérative de consommation à conseil d'administration":
    "SA coopérative de consommation à conseil d'administration",
  "SA nationale à conseil d'administration": "SA nationale à conseil d'administration",
  'SARL coopérative de transports': 'SARL coopérative de transports',
  "SCP d'avocats aux conseil": "SCP d'avocats aux conseil",
  'SCP de commissaires-priseurs': 'SCP de commissaires-priseurs',
  'SCP de notaires': 'SCP de notaires',
  "SCP d'infirmiers": "SCP d'infirmiers",
  SCPI: 'SCPI',
  'Società europea': 'Società europea',
  'Société civile coopérative de construction': 'Société civile coopérative de construction',
  'Sociétés Interprofessionnelles de Soins Ambulatoires':
    'Sociétés Interprofessionnelles de Soins Ambulatoires',
  'Association déclarée': 'Association déclarée',
  ASSOCIAZIONE: 'Associazione',
  'Auto entrepreneur': 'Auto entrepreneur',
  'Auto entreprise/profession libérale': 'Auto entreprise/profession libérale',
  "(Autre) Service déconcentré de l'Etat à compétence territoriale":
    "(Autre) Service déconcentré de l'Etat à compétence territoriale",
  'Autres personnes de droit privé inscrites au registre du commerce et des sociétés':
    'Autres personnes de droit privé inscrites au registre du commerce et des sociétés',
  'Caisse des écoles': 'Caisse des écoles',
  'Caisse (fédérale) de crédit mutuel': 'Caisse (fédérale) de crédit mutuel',
  "Centre communal d'action sociale": "Centre communal d'action sociale",
  "Communauté d'agglomération": "Communauté d'agglomération",
  'COOPERATIVA SOCIALE': 'Cooperativa sociale',
  'Ente ecclesiastico civilmente riconosciuto': 'Ente ecclesiastico civilmente riconosciuto',
  'Ente morale': 'Ente morale',
  'Entrepreneur Individuel': 'EI',
  'Entrepreneur Individuel (EI)': 'EI',
  "Etablissement public national à caractère industriel ou commercial doté d'un comptable public":
    "Etablissement public national à caractère industriel ou commercial doté d'un comptable public",
  GEIE: 'GEIE',
  GMBH: 'GMBH',
  'Groupement agricole foncier': 'Groupement agricole foncier',
  gUG: 'gUG',
  'Indivision avec personne morale': 'Indivision avec personne morale',
  Instituions: 'Instituions',
  'Institution de retraite complémentaire': 'Institution de retraite complémentaire',
  'Libero professionista / Lavoratore autonomo': 'Libero professionista / Lavoratore autonomo',
  'Micro-entreprise': 'Micro-entreprise',
  Ministère: 'Ministère',
  'Ordre professionnel ou assimilé': 'Ordre professionnel ou assimilé',
  'Organisation internationale': 'Organisation internationale',
  'PROFESSION LIBERALE': 'Profession libérale',
  "Représentation ou agence commerciale d'état ou organisme public étranger immatriculé au RCS":
    "Représentation ou agence commerciale d'état ou organisme public étranger immatriculé au RCS",
  SA: 'SA',
  "SA coopérative de production de HLM à conseil d'administration":
    "SA coopérative de production de HLM à conseil d'administration",
  "SA d'économie mixte à directoire": "SA d'économie mixte à directoire",
  'SA de crédit immobilier à directoire': 'SA de crédit immobilier à directoire',
  'S.A. HLM à directoire': 'S.A. HLM à directoire',
  'SA nationale à directoire': 'SA nationale à directoire',
  "SARL d'intérêt collectif agricole (SICA)": 'SICA',
  "SA union de sociétés coopératives à conseil d'administration":
    "SA union de sociétés coopératives à conseil d'administration",
  SCOP: 'SCOP',
  "SCOP à conseil d'administration": "SCOP à conseil d'administration",
  'SCP de dentistes': 'SCP de dentistes',
  'SCP de médecins': 'SCP de médecins',
  "SCP d'huissiers": "SCP d'huissiers",
  "Service central d'un ministère": "Service central d'un ministère",
  SL: 'SL',
  'Società consortile cooperativa a responsabilità limitata':
    'Società consortile cooperativa a responsabilità limitata',
  'Società consortile per azioni': 'Società consortile per azioni',
  "SOCIETA' COOPERATIVA A RESPONSABILITA LIMITATA": 'Società cooperativa a responsabilita limitata',
  "Société anonyme mixte d'intérêt collectif agricole (SICA) à conseil d'administration":
    "Société anonyme mixte d'intérêt collectif agricole (SICA) à conseil d'administration",
  "Société civile coopérative (d'intérêt) maritime":
    "Société civile coopérative (d'intérêt) maritime",
  "Société d'exercice libéral à forme anonyme à directoire":
    "Société d'exercice libéral à forme anonyme à directoire",
  "Société d'investissement à capital variable (SICAV) à conseil d'administration":
    "Société d'investissement à capital variable (SICAV) à conseil d'administration",
  'Syndicat intercommunal à vocation multiple (SIVOM)': 'SIVOM',
  'TEST CHANGEMENT RIB': 'TEST CHANGEMENT RIB',
  'Union de sociétés coopératives agricoles': 'Union de sociétés coopératives agricoles',
  'GmbH & Co. oHG': 'GmbH & Co. oHG',
  'GmbH & Co.KG': 'GmbH & Co.KG',
  'GmbH & Co KG': 'GmbH & Co KG',
  GbR: 'GbR',
};
