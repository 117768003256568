export const SIDES = {
  CREDIT: 'credit',
  DEBIT: 'debit',
};

export const PERIOD_KEYS = {
  THIS_MONTH: 'this_month',
  LAST_MONTH: 'last_month',
  THIS_YEAR: 'this_year',
  CUSTOM_PERIOD: 'custom_period',
};

export const BOOKKEEPING_STATUSES = {
  TO_VERIFY: 'to_verify',
  VERIFIED: 'verified',
};
