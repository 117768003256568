export const VAT_RATES = {
  NOT_AUTOMATIC: -2,
  OTHER: -1,
} as const;

export const VAT_STATUS = {
  auto: 'vat_auto',
  manual: 'vat_manual',
  confirmed: 'vat_confirmed',
  undetected: 'vat_not_found',
} as const;
