export const CONSENT_IS_REQUIRED_VENDORS = {
  AWIN: { id: 'c:awin-964zLYt8', name: 'Awin' },
  DRIFT: { id: 'c:drift', name: 'Drift' },
  AFINIA: { id: 'c:afinia-xiiQGwqD', name: 'Afinia' },
  HEADWAY: { id: 'c:headway-9Lyt8t63', name: 'Headway' },
  AFFILAE: { id: 'c:affilae', name: 'Affilae' },
  REFINER: { id: 'c:refiner-ne6mLbwB', name: 'Refiner' },
  OUTBRAIN: { id: 'c:outbrain', name: 'Outbrain' },
  LINKEDIN: { id: 'c:linkedin', name: 'LinkedIn' },
  REALYTICS: { id: 'c:realytics', name: 'Realytics' },
  AMPLITUDE: { id: 'c:amplitude', name: 'Amplitude' },
  PINTEREST: { id: 'c:pinterest-ads', name: 'Pinterest' },
  TRUSTPILOT: { id: 'c:trustpilot', name: 'TrustPilot' },
  FINANCE_ADS: { id: 'c:financeads', name: 'Finance Ads' },
  FLASHTALKING: { id: '78', name: 'Flashtalking' },
  FACEBOOK_ADS: { id: 'c:fb-ads', name: 'Facebook Ads' },
  SNAPCHAT_ADS: { id: 'c:snapchat', name: 'Snapchat Ads' },
  MICROSOFT_ADS: { id: 'c:bing-ads', name: 'Microsoft Ads' },
  GOOGLE_ADWORDS: { id: 'c:g-adwords', name: 'Google Adwords' },
  TRADE_DOUBLER: { id: 'c:tradedoubler', name: 'Trade Doubler' },
  CONTENTSQUARE: { id: 'c:contentsquare', name: 'ContentSquare' },
  DIGITAL_WINDOW: { id: 'c:digital-window', name: 'Digital Window' },
  ACTIVE_CAMPAIGN: { id: 'c:activecampaign', name: 'ActiveCampaign' },
  GOOGLE_ANALYTICS: { id: 'c:g-analytics', name: 'Google Analytics' },
  TWITTER_ANALYTICS: { id: 'c:twitter', name: 'Twitter Analytics' },
  GOOGLE_ADS_PRODUCTS: { id: 'c:google', name: 'Google Ads (Classic)' },
  GOOGLE_ANALYTICS_PRODUCTS: { id: 'c:googleana-4TXnJigR', name: 'Google Universal Analytics' },
  DOUBLE_CLICK_AND_FLOODLIGHT: { id: 'c:double-click', name: 'DoubleClick & Floodlight' },
};

export const TECHNICAL_VENDORS = {
  JUNE: { id: 'c:june', name: 'June' },
  CUSTOMER_IO: { id: 'c:customer-io', name: 'Customer.io' },
  SNOWFLAKE: { id: 'c:snowflake', name: 'Snowflake' },
  QONTO: { id: 'c:qonto', name: 'Qonto' },
  HOTJAR: { id: 'c:hotjar', name: 'Hotjar' },
  SENTRY: { id: 'c:sentry', name: 'Sentry' },
  SEGMENT: { id: 'c:segment', name: 'Segment' },
  SEGMENT_IO: { id: 'c:segment', name: 'Segment.io' },
  PRISMIC: { id: 'c:prismic', name: 'Prismic' },
  AB_TASTY: { id: 'c:abtasty', name: 'ABTasty' },
  GOOGLE_FONTS: { id: 'c:g-fonts', name: 'Google Fonts' },
  GOOGLE_TAG_MANAGER: { id: 'c:g-tag', name: 'Google Tag Manager' },
  FROSMO_OPTIMIZER: { id: 'c:frosmo-optimizer', name: 'Frosmo Optimizer' },
};
