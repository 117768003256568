const NRC_IBAN = {
  contasult: 'ES3868880001609637591820',
  declarando: 'ES8168880001666659233882',
};

const NRC_NAMES = {
  contasult: 'Contasult SL',
  declarando: 'Declarando SL',
};

export { NRC_IBAN, NRC_NAMES };
