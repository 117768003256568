export const HOTKEYS = {
  K: 'quick_actions',
  U: 'supplier_invoices',
  I: 'prompt',
};
export const QUICK_ACTIONS_DISCOVERABILITY_STORAGE_KEY = 'quick-actions-discoverability-viewed';

export function modifierKey(isMac) {
  if (isMac) {
    return '⌘';
  }

  return 'Ctrl';
}

export function initializeQuickActions(owner) {
  let router = owner.lookup('service:router');
  let flowLinkManager = owner.lookup('service:flow-link-manager');
  let intl = owner.lookup('service:intl');
  let qontoPilotService = owner.lookup('service:qonto-pilot-service');

  let quickActions = {
    [intl.t('qonto-pilot.modal.quick-action.group.recent')]: [],
    [intl.t('qonto-pilot.modal.quick-action.group.tasks')]: [
      {
        key: 'task-sepa',
        isEnabled: true,
        isBeta: true,
        copy: intl.t('qonto-pilot.modal.quick-action.sepa'),
        hotkey: 'I',
        illustration: 'icon_nav_transfer',
        action: () => {},
      },
      {
        key: 'task-receipt',
        isEnabled: true,
        hotkey: 'U',
        copy: intl.t('qonto-pilot.modal.quick-action.receipt'),
        illustration: 'icon_upload_outlined',
        action: () => {},
      },
    ],
    [intl.t('qonto-pilot.modal.quick-action.group.shortcuts')]: [
      {
        key: 'shortcut-transfer',
        isEnabled: true,
        copy: intl.t('qonto-pilot.modal.quick-action.make-transfer'),
        illustration: 'icon_shortcut_create_transfer',
        action: () => {
          router.transitionTo('transfers.landing');
        },
      },
      {
        key: 'shortcut-card',
        isEnabled: true,
        copy: intl.t('qonto-pilot.modal.quick-action.card'),
        illustration: 'icon_shortcut_create_card',
        action: () => {
          router.transitionTo('cards.physical-or-virtual');
        },
      },
      {
        key: 'shortcut-download-statements',
        isEnabled: true,
        copy: intl.t('qonto-pilot.modal.quick-action.account-statements'),
        illustration: 'icon_download_outlined',
        action: () => {
          router.transitionTo('accounts.statements');
        },
      },
      {
        key: 'shortcut-direct-debit',
        isEnabled: true,
        copy: intl.t('qonto-pilot.modal.quick-action.direct-debit'),
        illustration: 'icon_nav_transaction_unselected',
        action: () => {
          flowLinkManager.transitionTo({ name: 'mandate', stepId: 'landing' });
        },
      },
      {
        key: 'shortcut-add-account',
        isEnabled: true,
        copy: intl.t('qonto-pilot.modal.quick-action.account'),
        illustration: 'icon_shortcut_add_external_account',
        action: () => {
          router.transitionTo('accounts.new');
        },
      },
      {
        key: 'shortcut-setup-integration',
        isEnabled: true,
        copy: intl.t('qonto-pilot.modal.quick-action.integration'),
        illustration: 'icon_shortcut_integration',
        action: () => {
          router.transitionTo('settings.connect-hub.collections', {
            collection_slug: 'integrations',
          });
        },
      },
      {
        key: 'shortcut-invite-employee',
        isEnabled: true,
        copy: intl.t('qonto-pilot.modal.quick-action.member'),
        illustration: 'icon_user_plus_outlined',
        action: () => {
          flowLinkManager.transitionTo({
            name: 'member-invite',
            stepId: 'role',
          });
        },
      },
      {
        key: 'shortcut-expense-reimbursement',
        isEnabled: true,
        copy: intl.t('qonto-pilot.modal.quick-action.reimbursement'),
        illustration: 'icon_shortcut_reimbursed',
        action: () => {
          router.transitionTo('requests.landing');
        },
      },
      {
        key: 'shortcut-view-accounts',
        isEnabled: true,
        copy: intl.t('qonto-pilot.modal.quick-action.iban'),
        illustration: 'icon_iban',
        action: () => {
          router.transitionTo('accounts.index');
        },
      },
    ],
  };

  let recentKeys = qontoPilotService.getRecentQuickActions();
  let allActions = [
    ...quickActions[intl.t('qonto-pilot.modal.quick-action.group.tasks')],
    ...quickActions[intl.t('qonto-pilot.modal.quick-action.group.shortcuts')],
  ];

  quickActions[intl.t('qonto-pilot.modal.quick-action.group.recent')] = recentKeys
    .map(key => allActions.find(action => action.key === key))
    .filter(Boolean);

  return quickActions;
}

/**
 * To avoid tests having to follow these timings, delays are exported as objects so that their value can be changed in tests
 */
export const QONTO_PILOT_DELAYS = {
  supplierInvoices: 7000,
  searchInputDebounce: 500,
};
