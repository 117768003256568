export const STATUS = {
  COMPLETED: 'completed',
  DECLINED: 'declined',
  PENDING: 'pending',
  REVERSED: 'reversed',
  CANCELED: 'canceled',
};

export const CARD_TRANSACTION_STATUS = {
  COMPLETED_ZERO: 'completed_zero',
  PRE_AUTHORIZED_COMPLETED: 'pre_authorized_completed',
  PRE_AUTHORIZED_MORE_THAN_1000: 'pre_authorized_more_than_1000',
  PRE_AUTHORIZED_LESS_THAN_OR_EQUAL_TO_1000: 'pre_authorized_less_than_or_equal_to_1000',
  CANCELED: 'canceled',
};

export const DEFAULT_SEARCH_INCLUDES = [
  'cards',
  'checks',
  'claims',
  'direct_debits',
  'incomes',
  'transfers',
  'beneficiaries',
  'wallet_to_wallets',
  'swift_incomes',
  'pagopa_payments',
  'f24_payments',
  'nrc_payments',
  'financing_incomes',
  'financing_installments',
  'direct_debit_collections',
  'direct_debit_holds',
  'card_acquirer_payouts',
];

export const DEFAULT_SORT_BY = 'emitted_at:desc';

export const LINKED_ATTACHMENTS_LIMIT = 20;

export const TRANSACTIONS_ATTACHMENTS_LIMIT_REACHED = 'transaction attachments limit reached';

export const OPERATION_TYPES = {
  FINANCING_INSTALLMENT: 'financing_installment',
  FEE: 'fee',
  BILLER: 'biller',
};

export const SUBJECT_TYPES = {
  FINANCING_INSTALLMENT: 'FinancingInstallment',
  FINANCING_INCOME: 'FinancingIncome',
  DIRECT_DEBIT_COLLECTION: 'DirectDebitCollection',
  DIRECT_DEBIT_COLLECTION_R_TRANSACTION: 'DirectDebitCollectionRTransaction',
  DIRECT_DEBIT_HOLD: 'DirectDebitHold',
  NRC_PAYMENT: 'NrcPayment',
  CARD_ACQUIRER_PAYOUT: 'CardAcquirerPayout',
};

export const TRANSACTION_FEE_OPERATION_SUBTYPES = {
  PHYSICAL_CARD: 'physical_card',
  VIRTUAL_CARD: 'virtual_card',
  SEPA_INBOUND_TRANSFER: 'sepa_inbound_transfer',
  SEPA_OUTBOUND_TRANSFER: 'sepa_outbound_transfer',
  ATM: 'atm',
  FX_CARD: 'fx_card',
  FX_INBOUND_TRANSFER: 'fx_inbound_transfer',
  FX_OUTBOUND_TRANSFER: 'fx_outbound_transfer',
  FX_OUTBOUND_TRANSFER_LOCAL_NON_STANDARD_CURRENCY:
    'fx_outbound_transfer_local_non_standard_currency',
  FX_OUTBOUND_TRANSFER_LOCAL_STANDARD_CURRENCY: 'fx_outbound_transfer_local_standard_currency',
  FX_OUTBOUND_TRANSFER_SWIFT_OUR: 'fx_outbound_transfer_swift_our',
  FX_OUTBOUND_TRANSFER_SWIFT_SHA: 'fx_outbound_transfer_swift_sha',
  SUBSCRIPTION: 'subscription',
  DIRECT_DEBIT_COLLECTION_SEND: 'direct_debit_collection_send',
  DIRECT_DEBIT_COLLECTION_RETURN: 'direct_debit_collection_return',
  DIRECT_DEBIT_COLLECTION_REFUND: 'direct_debit_collection_refund',
  DIRECT_DEBIT_COLLECTION_REFUND_LATE: 'direct_debit_collection_refund_late',
  ACCOUNTS_PAYABLE: 'accounts_payable',
  ACCOUNTS_RECEIVABLE: 'accounts_receivable',
  EXPENSE_SPEND_MANAGEMENT: 'expense_spend_management',
};

export const CLAIM_STATUSES = {
  review: 'in_review',
  approved: 'approved',
  rejected: 'rejected',
};

export const CLAIM_TYPES = {
  atm: 'atm',
  commercial: 'commercial',
  fraud: 'fraud',
};

export const IN_DISPUTE_STATUS = 'disputing';

export const MODULAR_TABLE_LOCAL_STORAGE_KEY = 'has-modular-table-enabled';
