import { BADGE_TYPE } from '@qonto/ui-kit/constants/badge-types';
import dayjs from 'dayjs';

import {
  CODES,
  getTrackingNameAndProperties,
  LAYOUT,
  TYPES,
} from 'qonto/constants/empty-states/system';
import { INSIGHT_LABELS, INVOICE_STATUSES, TABS } from 'qonto/constants/supplier-invoice';

const name = 'supplier-invoices';
const featureName = 'supplierInvoices';
const EMPTY_LOTTIE_SRC = '/lotties/empty-state/no-supplier-invoices.json';
const EMPTY_LOTTIE_PENDING_SRC = '/lotties/empty-state/no-tasks.json';
const EMPTY_LOTTIE_NO_RESULTS_SRC = '/lotties/empty-state/search-no-result.json';

export const getDummyData = (intl, improveSITableFFEnabled) => {
  let hasMany = () => {
    return {
      ids: () => [],
    };
  };

  let currentDate = dayjs();
  let dueDate = currentDate.add(15, 'day').format('YYYY-MM-DD');
  let paymentDate = currentDate.add(15, 'day').format('YYYY-MM-DD');

  let items = [
    {
      supplierName: intl.t('supplier-invoices.section.empty-state.dummy-data.row-1.supplier'),
      invoiceNumber: intl.t('supplier-invoices.section.empty-state.dummy-data.row-1.number'),
      fileName: intl.t('supplier-invoices.section.empty-state.dummy-data.row-1.filename'),
      dueDate: currentDate.subtract(15, 'day').format('YYYY-MM-DD'),
      paymentDate: undefined,
      status: INVOICE_STATUSES.toPay,
      totalAmount: {
        currency: 'EUR',
        value: intl.t('supplier-invoices.section.empty-state.dummy-data.row-1.amount'),
      },
      hasMany,
      supplierSnapshot: {
        name: intl.t('supplier-invoices.section.empty-state.dummy-data.row-1.supplier'),
      },
      matchedTransactionsIds: [],
    },
    {
      supplierName: intl.t('supplier-invoices.section.empty-state.dummy-data.row-2.supplier'),
      invoiceNumber: intl.t('supplier-invoices.section.empty-state.dummy-data.row-2.number'),
      fileName: intl.t('supplier-invoices.section.empty-state.dummy-data.row-2.filename'),
      status: INVOICE_STATUSES.toPay,
      dueDate,
      paymentDate: undefined,
      totalAmount: {
        currency: 'EUR',
        value: intl.t('supplier-invoices.section.empty-state.dummy-data.row-2.amount'),
      },
      hasMany,
      supplierSnapshot: {
        name: intl.t('supplier-invoices.section.empty-state.dummy-data.row-2.supplier'),
      },
      matchedTransactionsIds: [],
    },
    {
      supplierName: intl.t('supplier-invoices.section.empty-state.dummy-data.row-3.supplier'),
      invoiceNumber: intl.t('supplier-invoices.section.empty-state.dummy-data.row-3.number'),
      fileName: intl.t('supplier-invoices.section.empty-state.dummy-data.row-3.filename'),
      status: INVOICE_STATUSES.scheduled,
      dueDate,
      paymentDate,
      totalAmount: {
        currency: 'EUR',
        value: intl.t('supplier-invoices.section.empty-state.dummy-data.row-3.amount'),
      },
      hasMany,
      supplierSnapshot: {
        name: intl.t('supplier-invoices.section.empty-state.dummy-data.row-3.supplier'),
      },
      matchedTransactionsIds: [],
    },
    {
      supplierName: intl.t('supplier-invoices.section.empty-state.dummy-data.row-4.supplier'),
      invoiceNumber: intl.t('supplier-invoices.section.empty-state.dummy-data.row-4.number'),
      fileName: intl.t('supplier-invoices.section.empty-state.dummy-data.row-4.filename'),
      status: INVOICE_STATUSES.paid,
      dueDate,
      paymentDate,
      totalAmount: {
        currency: 'EUR',
        value: intl.t('supplier-invoices.section.empty-state.dummy-data.row-4.amount'),
      },
      hasMany,
      supplierSnapshot: {
        name: intl.t('supplier-invoices.section.empty-state.dummy-data.row-4.supplier'),
      },
      matchedTransactionsIds: ['fake-id'],
    },
  ];

  // Leaving this comment for easily grepping the FF during the cleanup
  // variation('feature--boolean-improve-si-table')
  if (improveSITableFFEnabled) {
    return {
      tab: TABS.ALL_INVOICES,
      totalInvoicesCount: 72,
      insights: {
        [INSIGHT_LABELS.inboxCounter]: 26,
        [INSIGHT_LABELS.scheduledCounter]: 11,
        [INVOICE_STATUSES.inbox]: 2809,
        [INVOICE_STATUSES.scheduled]: 1847,
        [INVOICE_STATUSES.paid]: 2946,
        fxInvoices: true,
        [INSIGHT_LABELS.paidAmount]: 2946,
        [INSIGHT_LABELS.totalDueAmount]: 2809,
        [INSIGHT_LABELS.totalDueMissingAmmountCounter]: 0,
        [INSIGHT_LABELS.totalDueCounter]: 4,
        [INSIGHT_LABELS.totalDueFxCounter]: 0,
        [INSIGHT_LABELS.totalOverdueCounter]: 0,
        [INSIGHT_LABELS.totalUpcomingAmount]: 1847,
        [INSIGHT_LABELS.totalUpcomingCounter]: 5,
        [INSIGHT_LABELS.totalUpcomingFxCounter]: 0,
        [INSIGHT_LABELS.totalUpcomingMissingAmountCounter]: 0,
      },
      totals: {
        imported: intl.t('supplier-invoices.section.empty-state.dummy-data.imported-total.amount'),
        processing: intl.t(
          'supplier-invoices.section.empty-state.dummy-data.processing-total.amount'
        ),
        completed: intl.t(
          'supplier-invoices.section.empty-state.dummy-data.completed-total.amount'
        ),
      },
      items,
    };
  }

  return {
    status: INVOICE_STATUSES.toReview,
    insights: {
      [INSIGHT_LABELS.inboxCounter]: 26,
      [INSIGHT_LABELS.scheduledCounter]: 11,
      [INVOICE_STATUSES.inbox]: 2809,
      [INVOICE_STATUSES.scheduled]: 1847,
      [INVOICE_STATUSES.paid]: 2946,
      fxInvoices: true,
      [INSIGHT_LABELS.paidAmount]: 2946,
      [INSIGHT_LABELS.totalDueAmount]: 2809,
      [INSIGHT_LABELS.totalDueMissingAmmountCounter]: 0,
      [INSIGHT_LABELS.totalDueCounter]: 4,
      [INSIGHT_LABELS.totalDueFxCounter]: 0,
      [INSIGHT_LABELS.totalOverdueCounter]: 0,
      [INSIGHT_LABELS.totalUpcomingAmount]: 1847,
      [INSIGHT_LABELS.totalUpcomingCounter]: 5,
      [INSIGHT_LABELS.totalUpcomingFxCounter]: 0,
      [INSIGHT_LABELS.totalUpcomingMissingAmountCounter]: 0,
    },
    totals: {
      imported: intl.t('supplier-invoices.section.empty-state.dummy-data.imported-total.amount'),
      processing: intl.t(
        'supplier-invoices.section.empty-state.dummy-data.processing-total.amount'
      ),
      completed: intl.t('supplier-invoices.section.empty-state.dummy-data.completed-total.amount'),
    },
    items,
  };
};

const getToPayTabEmptyStateOutput = (
  intl,
  featureBooleanApprovalWorkflowForSupplierInvoicesFFEnabled,
  hasSupplierInvoicesUpdatePermission
) => {
  let title = intl.t('supplier-invoices.section.tabs.to-pay.i1.title');
  let subtitle = hasSupplierInvoicesUpdatePermission
    ? intl.t('supplier-invoices.section.tabs.to-pay.i1.subtitle')
    : intl.t('supplier-invoices.section.tabs.to-pay.i1.subtitle-no-permission');

  if (!featureBooleanApprovalWorkflowForSupplierInvoicesFFEnabled) {
    title = intl.t('supplier-invoices.section.tabs.imported.i1.title');
    subtitle = intl.t('supplier-invoices.section.tabs.imported.i1.body-authorised');
  }

  return {
    title,
    subtitle,
    lottieSrc: EMPTY_LOTTIE_SRC,
  };
};

export const getEmptyStateConfig = (
  intl,
  improveSITableFFEnabled,
  featureBooleanApprovalWorkflowForSupplierInvoicesFFEnabled,
  hasSupplierInvoicesUpdatePermission
) => {
  let dummyData = getDummyData(intl, improveSITableFFEnabled);

  return {
    name,
    featureName,
    variations: {
      ES_A3: {
        code: CODES.ES_A3,
        layout: LAYOUT.DISCOVER_PREVIEW,
        dummyData,
        badgeType: BADGE_TYPE.ACTIVATE,
        badgeText: intl.t('empty-states.system.activate.a3.badge'),
        tracking: getTrackingNameAndProperties({ name, type: TYPES.ACTIVATE }),
        variations: [
          {
            inputs: {
              activateAbilities: ['canUploadSupplierInvoices'],
              copyAbilities: ['cannotReadTransfers', 'cannotAccessRequestTransfers'],
            },
            output: {
              title: intl.t('supplier-invoices.section.empty-state.a3.bankless.title'),
              subtitle: intl.t('supplier-invoices.section.empty-state.a3.bankless.subtitle'),
            },
          },
          {
            inputs: {
              activateAbilities: ['canUploadSupplierInvoices'],
              copyAbilities: ['canUpdateSupplierInvoices'],
            },
            output: {
              title: intl.t('supplier-invoices.section.empty-state.a3.transfer.title'),
              subtitle: intl.t('supplier-invoices.section.empty-state.a3.transfer.subtitle'),
            },
          },
          {
            inputs: {
              activateAbilities: ['canUploadSupplierInvoices'],
            },
            output: {
              title: intl.t('supplier-invoices.section.empty-state.a3.transfer.title'),
              subtitle: intl.t('supplier-invoices.section.empty-state.a3.no-transfer.subtitle'),
            },
          },
        ],
      },
      ES_I1: {
        code: CODES.ES_I1,
        layout: LAYOUT.INFORM,
        tracking: getTrackingNameAndProperties({ name, type: TYPES.INFORM }),
        variations: [
          {
            inputs: {
              customInputs: {
                tab: improveSITableFFEnabled ? TABS.TO_PAY : INVOICE_STATUSES.toReview,
              },
            },
            output: getToPayTabEmptyStateOutput(
              intl,
              featureBooleanApprovalWorkflowForSupplierInvoicesFFEnabled,
              hasSupplierInvoicesUpdatePermission
            ),
          },
          {
            inputs: {
              customInputs: {
                tab: TABS.INBOX,
              },
            },
            output: {
              title: intl.t('supplier-invoices.section.tabs.inbox.i1.title'),
              subtitle: intl.t('supplier-invoices.section.tabs.inbox.i1.subtitle'),
              lottieSrc: EMPTY_LOTTIE_SRC,
            },
          },
          {
            inputs: {
              customInputs: {
                tab: TABS.TO_APPROVE,
              },
            },
            output: {
              title: intl.t('supplier-invoices.section.tabs.to-approve.i1.title'),
              subtitle: intl.t('supplier-invoices.section.tabs.to-approve.i1.subtitle'),
              lottieSrc: EMPTY_LOTTIE_SRC,
            },
          },
          {
            inputs: {
              customInputs: {
                tab: improveSITableFFEnabled ? TABS.SCHEDULED : INVOICE_STATUSES.scheduled,
              },
              copyAbilities: ['canUpdateSupplierInvoices'],
            },
            output: {
              title: improveSITableFFEnabled
                ? intl.t('supplier-invoices.scheduled.empty.title')
                : intl.t('supplier-invoices.section.tabs.processing.i1.title'),
              subtitle: improveSITableFFEnabled
                ? intl.t('supplier-invoices.scheduled.empty.subtitle')
                : intl.t('supplier-invoices.section.tabs.processing.i1.body-authorised'),
              lottieSrc: EMPTY_LOTTIE_SRC,
            },
          },
          {
            inputs: {
              customInputs: {
                tab: improveSITableFFEnabled ? TABS.SCHEDULED : INVOICE_STATUSES.scheduled,
              },
            },
            output: {
              title: improveSITableFFEnabled
                ? intl.t('supplier-invoices.scheduled.empty.title')
                : intl.t('supplier-invoices.section.tabs.processing.i1.title'),
              subtitle: improveSITableFFEnabled
                ? intl.t('supplier-invoices.scheduled.empty.subtitle')
                : intl.t('supplier-invoices.section.tabs.processing.i1.body'),
              lottieSrc: EMPTY_LOTTIE_SRC,
            },
          },
          {
            inputs: {
              customInputs: {
                tab: improveSITableFFEnabled ? TABS.TASKS : INVOICE_STATUSES.pending,
              },
            },
            output: {
              title: intl.t('supplier-invoices.section.tabs.to-approve.i1.title'),
              subtitle: intl.t('supplier-invoices.section.tabs.to-approve.i1.body'),
              lottieSrc: EMPTY_LOTTIE_PENDING_SRC,
            },
          },
          {
            inputs: {
              customInputs: {
                tab: 'requests',
              },
            },
            output: {
              title: intl.t('supplier-invoices.section.tabs.pending-requests.i1.title'),
              subtitle: intl.t('supplier-invoices.section.tabs.pending-requests.i1.body'),
              lottieSrc: EMPTY_LOTTIE_PENDING_SRC,
            },
          },
          {
            inputs: {
              customInputs: {
                tab: improveSITableFFEnabled ? TABS.COMPLETED : INVOICE_STATUSES.paid,
              },
              copyAbilities: ['canUpdateSupplierInvoices'],
            },
            output: {
              title: intl.t('supplier-invoices.section.tabs.completed.i1.title'),
              subtitle: intl.t('supplier-invoices.section.tabs.completed.i1.body-authorised'),
              lottieSrc: EMPTY_LOTTIE_SRC,
            },
          },
          {
            inputs: {
              customInputs: {
                tab: improveSITableFFEnabled ? TABS.COMPLETED : INVOICE_STATUSES.paid,
              },
            },
            output: {
              title: intl.t('supplier-invoices.section.tabs.completed.i1.title'),
              subtitle: intl.t('supplier-invoices.section.tabs.completed.i1.body'),
              lottieSrc: EMPTY_LOTTIE_SRC,
            },
          },
          {
            inputs: {
              customInputs: {
                tab: improveSITableFFEnabled
                  ? TABS.COMPLETED
                  : `${INVOICE_STATUSES.paid},${INVOICE_STATUSES.archived}`,
              },
              copyAbilities: ['canUpdateSupplierInvoices'],
            },
            output: {
              title: intl.t('supplier-invoices.section.tabs.completed.i1.title'),
              subtitle: intl.t('supplier-invoices.section.tabs.completed.i1.body-authorised'),
              lottieSrc: EMPTY_LOTTIE_SRC,
            },
          },
          {
            inputs: {
              customInputs: {
                tab: improveSITableFFEnabled
                  ? TABS.COMPLETED
                  : `${INVOICE_STATUSES.paid},${INVOICE_STATUSES.archived}`,
              },
            },
            output: {
              title: intl.t('supplier-invoices.section.tabs.completed.i1.title'),
              subtitle: intl.t('supplier-invoices.section.tabs.completed.i1.body'),
              lottieSrc: EMPTY_LOTTIE_SRC,
            },
          },
        ],
      },
      ES_I2: {
        code: CODES.ES_I2,
        layout: LAYOUT.INFORM,
        lottieSrc: EMPTY_LOTTIE_NO_RESULTS_SRC,
        tracking: getTrackingNameAndProperties({ name, type: TYPES.INFORM }),
        variations: [
          {
            output: {
              title: intl.t('empty-states.system.inform.i2.title'),
              subtitle: intl.t('empty-states.system.inform.i2.subtitle'),
            },
          },
        ],
      },
    },
  };
};
