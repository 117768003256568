export const STATUS = {
  ACTIVE: 'active',
  CLOSING: 'closing',
  CLOSED: 'closed',
  PROCESSING: 'processing',
};

export const SORT_BY = ['sortStatusOrder', 'sortDate', 'sequentialId'];

export const MIN_SAVINGS_ACCOUNT_AMOUNT = 5000;
export const NOTICE_PERIOD_AMOUNT_THRESHOLD = 1000000;

export const MY_MONEY_BANK_IBAN = 'FR504279900009130004999BH06';

export const SAVINGS_ACCOUNT_TRANSFER_OPTIONS = {
  name: 'Term Deposit',
  iban: MY_MONEY_BANK_IBAN,
  reference: '838029038042',
  activityTag: 'treasury_and_interco',
};

export const DUMMY_FILE = {
  fileUrl:
    'data:image/jpeg;base64,/9j/2wBDAAMCAgICAgMCAgIDAwMDBAYEBAQEBAgGBgUGCQgKCgkICQkKDA8MCgsOCwkJDRENDg8QEBEQCgwSExIQEw8QEBD/yQALCAABAAEBAREA/8wABgAQEAX/2gAIAQEAAD8A0s8g/9k=',
  fileName: 'dummy_file.jpeg',
};

export const MAX_TERM_DEPOSIT_ACCOUNTS = 10;
