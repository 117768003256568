export const NOTIFICATION_CATEGORIES = {
  ACCOUNT: 'account',
  BUDGETS: 'budgets',
  CARD: 'card',
  CHECK: 'check',
  REQUEST: 'request',
};

export const NOTIFICATION_CATEGORIES_ORDER = [
  NOTIFICATION_CATEGORIES.ACCOUNT,
  NOTIFICATION_CATEGORIES.CARD,
  NOTIFICATION_CATEGORIES.REQUEST,
  NOTIFICATION_CATEGORIES.BUDGETS,
  NOTIFICATION_CATEGORIES.CHECK,
];
