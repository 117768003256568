export const PAY_LATER_ELIGIBILITY_STATUSES = {
  ELIGIBLE: 'eligible',
  NON_ELIGIBLE: 'non-eligible',
  NO_MORE_ELIGIBLE: 'no-more-eligible',
};

export const PAY_LATER_SIGNATURE_STATUS = {
  SUCCESS: 'success',
  ERROR: 'error',
};

export const REPAYMENT_STATUS = {
  AT_RISK: 'at_risk',
  LATE: 'late',
  FROZEN: 'frozen',
};

export const CONTRACT_STATUS = {
  NOT_SIGNED: 'not_signed',
  SIGNED: 'signed',
  ON_HOLD: 'on_hold',
};

export const FAQ_TRACKING_ORIGIN = {
  ELIGIBLE: 'cockpit_eligible',
  ON_HOLD: 'cockpit_onhold',
  NO_MORE_ELIGIBLE: 'cockpit_no_more_eligible',
  DEDICATED_FLOW: 'dedicated_flow',
};

export const PAY_LATER_MINIMUM_TRANSFER_AMOUNT = 150;

export const PAY_LATER_SIGNATURE_EVENT = {
  ORIGIN: 'https://yousign.app',
  TYPE: 'yousign',
};

export const FINANCING_INSTALLMENT_STATUS = {
  SCHEDULED: 'scheduled',
  PROCESSING: 'processing',
  FROZEN: 'frozen',
  NOT_PAID: 'not_paid',
  RECOVERED: 'recovered',
  MONEY_LOSS: 'money_loss',
  CANCELLED: 'cancelled',
  PAID: 'paid',
};

export const FINANCING_TRANSFER_STATUS = {
  PROCESSING: 'processing',
  IN_PROGRESS: 'in_progress',
  COMPLETED: 'completed',
  DECLINED: 'declined',
  PROCESSING_REPAYMENT: 'processing_repayment',
};

export const COMPLETED_INSTALLMENT_STATUSES = [
  FINANCING_INSTALLMENT_STATUS.RECOVERED,
  FINANCING_INSTALLMENT_STATUS.MONEY_LOSS,
  FINANCING_INSTALLMENT_STATUS.CANCELLED,
  FINANCING_INSTALLMENT_STATUS.PAID,
];

export const IN_PROGRESS_INSTALLMENT_STATUSES = [
  FINANCING_INSTALLMENT_STATUS.SCHEDULED,
  FINANCING_INSTALLMENT_STATUS.PROCESSING,
  FINANCING_INSTALLMENT_STATUS.FROZEN,
  FINANCING_INSTALLMENT_STATUS.NOT_PAID,
];

export const IN_PROGRESS_FINANCING_STATUSES = [
  FINANCING_TRANSFER_STATUS.IN_PROGRESS,
  FINANCING_TRANSFER_STATUS.PROCESSING,
];

export const COMPLETED_FINANCING_STATUSES = [
  FINANCING_TRANSFER_STATUS.COMPLETED,
  FINANCING_TRANSFER_STATUS.DECLINED,
];

export const PAY_LATER_THRESHOLD = {
  MAIN_ACCOUNT_LOW_BALANCE: 2000,
  MINIMUM_CREDIT_AMOUNT_VALUE: 150,
};

export const PAY_LATER_APPLICATION_ORIGIN = {
  NAVIGATION: 'navigation',
  COCKPIT: 'cockpit',
  TRANSFER: 'transfer',
  PAY_BY_BENEFICIARY: 'pay_by_beneficiary',
  SUPPLIER_INVOICES: 'supplier_invoices',
};

export const INVOICE_ATTACHMENT_LIMITS = {
  issue_date_days_past: 60,
  due_date_days_limit: 60,
};

export const DEFAULT_ELIGIBILITY_RESPONSE = {
  available_credit_amount: {
    value: '10000.00',
    currency: 'EUR',
  },
  credit_limit_amount: {
    value: '10000.00',
    currency: 'EUR',
  },
  min_transfer_amount: {
    value: '150.00',
    currency: 'EUR',
  },
  contract_status: CONTRACT_STATUS.SIGNED,
  eligibility: PAY_LATER_ELIGIBILITY_STATUSES.ELIGIBLE,
  pay_later_transfers_count: 0,
  invoice_attachment_limits: INVOICE_ATTACHMENT_LIMITS,
};

export const DEFAULT_INSTALLMENTS_RESPONSE = {
  available_credit_amount: {
    value: '10000.00',
    currency: 'EUR',
  },
  remaining_credit_after_transfer: {
    value: '5000.00',
    currency: 'EUR',
  },
  options: [
    {
      number_of_installments: 3,
      total_amount: {
        value: '5000.00',
        currency: 'EUR',
      },
      total_fees: {
        value: '115.98',
        currency: 'EUR',
      },
      monthly_amount: {
        value: '1705.33',
        currency: 'EUR',
      },
      monthly_interest_rate: '1.16',
    },
    {
      number_of_installments: 6,
      total_amount: {
        value: '5000.00',
        currency: 'EUR',
      },
      total_fees: {
        value: '204.13',
        currency: 'EUR',
      },
      monthly_amount: {
        value: '867.36',
        currency: 'EUR',
      },
      monthly_interest_rate: '1.16',
    },
    {
      number_of_installments: 9,
      total_amount: {
        value: '5000.00',
        currency: 'EUR',
      },
      total_fees: {
        value: '293.26',
        currency: 'EUR',
      },
      monthly_amount: {
        value: '588.14',
        currency: 'EUR',
      },
      monthly_interest_rate: '1.16',
    },
  ],
};

export const PAY_LATER_VALIDATION_ERROR_TYPES = {
  INVALID_DATE: 'invalid_field_errors',
  SELF_TRANSFER: 'transfer',
  MISSING_DETAILS: 'missing_field_errors',
  ATTACHMENT_ID: 'attachment_id',
};

export const LOCAL_STORAGE_SI_PAY_LATER_ACTION_NEW_BADGE_DISPLAYED_KEY =
  'supplier-invoices-pay-later-action-new-badge-displayed';

export const PAY_LATER_VALIDATION_ERRORS = {
  PREVENT_FF: 'prevent_pay_later_transfer_feature_flag_enabled',
  AMOUNT_LESS_THAN_THRESHOLD: 'amount_less_than_threshold',
  AMOUNT_MORE_THAN_AVAILABLE_CREDIT: 'insufficient_available_credit',
  INVALID_ISSUE_DATE: 'invoice_issue_date_too_old',
  INVALID_DUE_DATE: 'invoice_due_date_outside_valid_timespan',
  MISSING_INVOICE_AMOUNT: 'missing_invoice_amount',
  MISSING_ISSUE_DATE: 'missing_issue_date',
  MISSING_SUPPLIER_IDENTIFIER: 'missing_supplier_identifier',
  MISSING_SUPPLIER_NAME: 'missing_supplier_name',
  SELF_TRANSFER: 'self_transfer',
  NOT_MAIN_ACCOUNT: 'main_account_not_selected',
  SCHEDULING_RECURRENCE_ENABLED: 'scheduled_or_recurring_transfer_not_allowed',
};

export const PAY_LATER_ERRORS_PRIORITY = [
  PAY_LATER_VALIDATION_ERRORS.PREVENT_FF,
  PAY_LATER_VALIDATION_ERRORS.AMOUNT_LESS_THAN_THRESHOLD,
  PAY_LATER_VALIDATION_ERRORS.AMOUNT_MORE_THAN_AVAILABLE_CREDIT,
  PAY_LATER_VALIDATION_ERRORS.INVALID_ISSUE_DATE,
  PAY_LATER_VALIDATION_ERRORS.INVALID_DUE_DATE,
  PAY_LATER_VALIDATION_ERRORS.MISSING_SUPPLIER_NAME,
  PAY_LATER_VALIDATION_ERRORS.MISSING_INVOICE_AMOUNT,
  PAY_LATER_VALIDATION_ERRORS.MISSING_ISSUE_DATE,
  PAY_LATER_VALIDATION_ERRORS.MISSING_SUPPLIER_IDENTIFIER,
  PAY_LATER_VALIDATION_ERRORS.SELF_TRANSFER,
  PAY_LATER_VALIDATION_ERRORS.NOT_MAIN_ACCOUNT,
  PAY_LATER_VALIDATION_ERRORS.SCHEDULING_RECURRENCE_ENABLED,
];

export const MIN_TRANSFER_AMOUNT = {
  value: '150.00',
  currency: 'EUR',
};

export const PAY_LATER_FLOW_ORIGIN = {
  COCKPIT: 'cockpit',
  DEEPLINK: 'deeplink',
  GET_STARTED_CARD: 'get_started_card',
  SUCCESS_SCREEN: 'success_screen',
  TRANSFER_FLOW: 'transfer_flow',
  PROMOTIONAL_CARD: 'promotional_card',
  PAY_BY_BENEFICIARY: 'pay_by_beneficiary',
  PAY_BY_INVOICE: 'pay_by_invoice',
  SUPPLIER_INVOICES: 'supplier_invoices',
};

export const PAY_LATER_PROMO_BOX_LOCAL_STORAGE_KEY = 'pay-later-promotional-card';
export const PAY_LATER_PROMOTIONAL_TOOLTIP_LOCAL_STORAGE_KEY = 'pay-later-promotional-tooltip';

export const FINANCING_TRANSFER_DECLINED_REASON = 'financing_declined';
