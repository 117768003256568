import { BADGE_TYPE } from '@qonto/ui-kit/constants/badge-types';
import dayjs from 'dayjs';

import {
  CODES,
  getTrackingNameAndProperties,
  LAYOUT,
  TYPES,
} from 'qonto/constants/empty-states/system';
import { STATUS } from 'qonto/constants/receivable-invoice';

const TAB_NAMES = {
  DRAFT: 'draft',
  COMPLETED: 'completed',
  PENDING: 'pending',
};

const featureName = 'receivableInvoicesV2';
const name = 'client-invoices';

const INFORM_LOTTIE_SRC = '/lotties/receivable-invoices/empty-state-inform.json';
const EMPTY_RESULTS_LOTTIE_SRC = '/lotties/empty-state/search-no-result.json';

export const getEmptyStateConfig = intl => {
  let dummyData = getDummyData(intl);

  return {
    name,
    featureName,

    variations: {
      ES_A3: {
        code: CODES.ES_A3,
        layout: LAYOUT.DISCOVER_PREVIEW,
        dummyData,
        tracking: getTrackingNameAndProperties({ name, type: TYPES.ACTIVATE }),
        variations: [
          /** Variations with custom inputs need to be defined first */
          {
            inputs: {
              customInputs: {
                showOnboarding: true,
              },
            },
            output: {
              badgeType: BADGE_TYPE.ACTIVATE,
              badgeText: intl.t('empty-states.system.activate.a3.badge'),
              title: intl.t('receivable-invoices.invoices-list.empty-state.a3.title'),
              subtitle: intl.t(
                'receivable-invoices.invoices-list.empty-state.a3.onboarding.subtitle'
              ),
            },
          },
          {
            output: {
              badgeType: BADGE_TYPE.ACTIVATE,
              badgeText: intl.t('empty-states.system.activate.a3.badge'),
              title: intl.t('receivable-invoices.invoices-list.empty-state.a3.title'),
              subtitle: intl.t(
                'receivable-invoices.invoices-list.empty-state.a3.unlimited.subtitle'
              ),
            },
          },
        ],
      },

      ES_I1: {
        code: CODES.ES_I1,
        layout: LAYOUT.INFORM,
        tracking: getTrackingNameAndProperties({ name, type: TYPES.INFORM }),
        lottieSrc: INFORM_LOTTIE_SRC,
        variations: [
          {
            inputs: {
              customInputs: {
                tab: TAB_NAMES.DRAFT,
              },
            },
            output: {
              title: intl.t('receivable-invoices.invoices-list.tabs.drafts.i1.title'),
              subtitle: intl.t('receivable-invoices.invoices-list.tabs.drafts.i1.body'),
            },
          },

          {
            inputs: {
              customInputs: {
                tab: TAB_NAMES.PENDING,
              },
            },
            output: {
              title: intl.t('receivable-invoices.invoices-list.tabs.pending.i1.title'),
              subtitle: intl.t('receivable-invoices.invoices-list.tabs.pending.i1.body'),
            },
          },

          {
            inputs: {
              customInputs: {
                tab: TAB_NAMES.COMPLETED,
              },
            },
            output: {
              title: intl.t('receivable-invoices.invoices-list.tabs.completed.i1.title'),
              subtitle: intl.t('receivable-invoices.invoices-list.tabs.completed.i1.body'),
            },
          },
        ],
      },

      ES_I2: {
        code: CODES.ES_I2,
        layout: LAYOUT.INFORM,
        lottieSrc: EMPTY_RESULTS_LOTTIE_SRC,
        tracking: getTrackingNameAndProperties({ name, type: TYPES.INFORM }),
        variations: [
          {
            output: {
              title: intl.t('empty-states.system.inform.i2.title'),
              subtitle: intl.t('empty-states.system.inform.i2.subtitle'),
            },
          },
        ],
      },
    },
  };
};

export const getDummyData = intl => {
  let hasMany = () => {
    return {
      ids: () => [],
    };
  };

  let currentDate = dayjs();
  let issueDate = currentDate.subtract(15, 'day').format('YYYY-MM-DD');
  let dueDate = currentDate.add(15, 'day').format('YYYY-MM-DD');

  return {
    status: STATUS.DRAFT,
    totalCount: 4,
    columnsToShow: {
      customer: true,
      issueDate: true,
      dueDate: true,
      amountDue: true,
    },
    items: [
      // 1st Row
      {
        customerSnapshot: {
          name: intl.t('receivable-invoices.invoices-list.empty-state.dummy-data.row-1.client'),
        },
        issueDate,
        dueDate,
        amountDue: intl.t('receivable-invoices.invoices-list.empty-state.dummy-data.row-1.amount'),
        hasMany,
      },
      // 2nd Row
      {
        customerSnapshot: {
          name: intl.t('receivable-invoices.invoices-list.empty-state.dummy-data.row-2.client'),
        },
        issueDate,
        dueDate,
        amountDue: intl.t('receivable-invoices.invoices-list.empty-state.dummy-data.row-2.amount'),
        hasMany,
      },
      // 3rd Row
      {
        customerSnapshot: {
          name: intl.t('receivable-invoices.invoices-list.empty-state.dummy-data.row-3.client'),
        },
        issueDate,
        dueDate,
        amountDue: intl.t('receivable-invoices.invoices-list.empty-state.dummy-data.row-3.amount'),
        hasMany,
      },
      // 4th Row
      {
        customerSnapshot: {
          name: intl.t('receivable-invoices.invoices-list.empty-state.dummy-data.row-4.client'),
        },
        issueDate,
        dueDate,
        amountDue: intl.t('receivable-invoices.invoices-list.empty-state.dummy-data.row-4.amount'),
        hasMany,
      },
    ],
  };
};
