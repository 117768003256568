export const ANNUAL_TURNOVER_OPTIONS = [
  '0-100000',
  '100000-1000000',
  '1000000-30000000',
  '30000000-100000000',
  '100000000-',
];

export const ANNUAL_TURNOVER_OPTIONS_IT = [
  '0-100000',
  '100000-2000000',
  '2000000-30000000',
  '30000000-100000000',
  '100000000-',
];

export const YEARLY_TRANSACTION_VOLUME_OPTIONS = [
  '0-10000',
  '10000-50000',
  '50000-100000',
  '100000-500000',
  '500000-',
];
