export let companyTypes = {
  FR: [
    '5720',
    '5499',
    '5710',
    '1000',
    '5498',
    '1500',
    '1300',
    '6540',
    '6599',
    '5599',
    '5202',
    '5306',
    '3120',
    '6220',
    '7323',
    '9224',
    '9220',
    '9221',
    '9230',
    '9222',
    '7321',
    '9150',
  ],
  IT: [
    'IT001',
    'IT002',
    'AC_lowercase',
    'IT401',
    'IT201',
    'IT301',
    'IT701',
    'IT702',
    'IT703',
    'IT704',
    'IT705',
    'SC_lowercase',
  ],
  DE: [
    'DE206',
    'DE002',
    'DE101',
    'DE207',
    'DE001',
    'DE210',
    'DE208',
    'DE201',
    'DE107',
    'DE103',
    'DE102',
    'DE108',
    'DE112',
    'DE202',
    'DE110',
    'DE111',
  ],
};

export const EINZELUNTERNEHMER_LEGAL_CODE = 'DE002';
export const FREIBERUFLER_LEGAL_CODE = 'DE210';
export const GBR_LEGAL_CODE = 'DE101';
export const GMBH_IN_IG_LEGAL_CODE = 'DE306';
export const UG_IN_IG_LEGAL_CODE = 'DE307';
