export const EVENTS = {
  ATTACHMENT_UPLOADED: 'transfer-swift_attachment_uploaded',
  ATTACHMENT_SUBMITTED: 'transfer-swift_attachment_confirmed',
  BENEFICIARY_REQUIREMENTS_ACCEPTED: 'transfer-swift_beneficiary-requirements_submitted',
  BENEFICIARY_REQUIREMENTS_SUBMITTED: 'transfer-swift_beneficiary-requirements_clicked',
  DELETE_BENEFICIARY: 'transfer-swift_beneficiary_deleted',
  INPUT_AMOUNT: 'transfer-swift_amount_inputted',
  NEW_BENEFICIARY: 'transfer-swift_new-beneficiary_clicked',
  PROMOTIONAL_CARD_CLICKED: 'transfer-swift_promotional-card_clicked',
  QUOTE_EXPIRED: 'transfer-swift_quote_expired',
  SELECT_BENEFICIARY: 'transfer-swift_beneficiary_selected',
  SELECT_CURRENCY: 'transfer-swift_currency_selected',
  START_FLOW: 'transfer-swift_quote_displayed',
  TRANSFER_CREATED: 'transfer-swift_transfer_created',
  TRANSFER_REQUIREMENTS_SUBMITTED: 'transfer-swift_requirements_confirmed',
  TRANSFER_SUBMITTED: 'transfer-swift_summary_confirmed',
  TRANSFER_SUMMARY_ACCESSED: 'transfer-swift_summary_entered',
  VIEW_BENEFICIARY: 'transfer-swift_beneficiary_view-clicked',
  VOUCHER_BANNER_CLICKED: 'transfer-swift_voucher-banner_clicked',
  VOUCHER_BANNER_DISPLAYED: 'transfer-swift_voucher-banner_displayed',
};
