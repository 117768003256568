import { BADGE_TYPE } from '@qonto/ui-kit/constants/badge-types';

import {
  CODES,
  getTrackingNameAndProperties,
  LAYOUT,
  TYPES,
} from 'qonto/constants/empty-states/system';

const name = 'suppliers-list';
const featureName = 'suppliersManagement';

const EMPTY_RESULTS = '/lotties/empty-state/search-no-result.json';

export const CREATE_TRANSFERS_AND_REVIEW_REQUEST_TRANSFERS =
  'create_transfers_and_review_request_transfers';

export const getEmptyStateConfig = (
  intl,
  { makeTransferCtaCallback, requestTransferCtaCallback }
) => {
  let dummyData = getDummyData(intl);

  return {
    name,
    featureName,
    variations: {
      ES_A3: {
        code: CODES.ES_A3,
        layout: LAYOUT.DISCOVER_PREVIEW,
        tracking: getTrackingNameAndProperties({ name, type: TYPES.ACTIVATE }),
        badgeType: BADGE_TYPE.ACTIVATE,
        dummyData,
        badgeText: intl.t('empty-states.system.activate.a3.badge'),
        variations: [
          {
            inputs: {
              activateAbilities: ['canCreateBeneficiaries', 'canCreateTransfer'],
            },
            output: {
              title: intl.t('supplier-list.section.empty-state.a3.transfer.title'),
              subtitle: intl.t('supplier-list.section.empty-state.a3.transfer.subtitle'),
              ctaText: intl.t('supplier-list.section.empty-state.a3.transfer.cta'),
              onClick: makeTransferCtaCallback,
            },
          },
          {
            inputs: {
              activateAbilities: [
                'canAccessBeneficiaries',
                'canRequestTransfer',
                'hasRequestsFeature',
              ],
            },
            output: {
              title: intl.t('supplier-list.section.empty-state.a3.transfer.title'),
              subtitle: intl.t('supplier-list.section.empty-state.a3.no-transfer.subtitle'),
              ctaText: intl.t('supplier-invoices.a3.cta.request-transfer'),
              onClick: requestTransferCtaCallback,
            },
          },
          {
            output: {
              title: intl.t('supplier-list.section.empty-state.i3.transfer.title'),
              subtitle: intl.t('supplier-list.section.empty-state.i3.no-transfer.subtitle'),
              code: CODES.ES_I3,
              tracking: getTrackingNameAndProperties({ name, type: TYPES.INFORM }),
            },
          },
        ],
      },
      ES_I2: {
        code: CODES.ES_I2,
        layout: LAYOUT.INFORM,
        lottieSrc: EMPTY_RESULTS,
        tracking: getTrackingNameAndProperties({ name, type: TYPES.INFORM }),
        variations: [
          {
            output: {
              title: intl.t('empty-states.system.inform.i2.title'),
              subtitle: intl.t('empty-states.system.inform.i2.subtitle'),
            },
          },
        ],
      },

      ES_I3: {
        code: CODES.ES_I3,
        layout: LAYOUT.DISCOVER_PREVIEW,
        dummyData,
        badgeType: BADGE_TYPE.ACTIVATE,
        badgeText: intl.t('empty-states.system.activate.a3.badge'),
        tracking: getTrackingNameAndProperties({ name, type: TYPES.INFORM }),
        variations: [
          {
            output: {
              title: intl.t('supplier-list.section.empty-state.i3.transfer.title'),
              subtitle: intl.t('supplier-list.section.empty-state.i3.no-transfer.subtitle'),
            },
          },
        ],
      },
    },
  };
};

export const getDummyData = intl => {
  let hasMany = () => {
    return {
      ids: () => [],
    };
  };

  return {
    // The count on the filter
    missingInformationCount: 1,
    items: [
      // 1st Row
      {
        name: intl.t('supplier-list.section.empty-state.dummy-data.row-1.supplier'),
        currency: 'EUR',
        accountNumber: intl.t('supplier-list.section.empty-state.dummy-data.row-1.iban'),
        vatRate: 20,
        labels: [
          {
            labelList: {
              name: intl.t('supplier-list.section.empty-state.dummy-data.row-1.custom-label'),
              color: '#FFD4E3',
            },
            name: intl.t('supplier-list.section.empty-state.dummy-data.row-1.custom-label'),
          },
        ],
        hasMany,
      },
      // 2nd Row
      {
        name: intl.t('supplier-list.section.empty-state.dummy-data.row-2.supplier'),
        currency: 'EUR',
        accountNumber: intl.t('supplier-list.section.empty-state.dummy-data.row-2.iban'),
        vatRate: '',
        labels: [
          {
            labelList: {
              name: intl.t('supplier-list.section.empty-state.dummy-data.row-2.custom-label'),
              color: '#91E89D',
            },
            name: intl.t('supplier-list.section.empty-state.dummy-data.row-2.custom-label'),
          },
        ],
        hasMany,
      },
      // 3rd Row
      {
        name: intl.t('supplier-list.section.empty-state.dummy-data.row-3.supplier'),
        currency: 'EUR',
        accountNumber: intl.t('supplier-list.section.empty-state.dummy-data.row-3.iban'),
        vatRate: 10,
        labels: [
          {
            labelList: {
              name: intl.t('supplier-list.section.empty-state.dummy-data.row-3.custom-label'),
              color: '#FFF4BC',
            },
            name: intl.t('supplier-list.section.empty-state.dummy-data.row-3.custom-label'),
          },
        ],
        hasMany,
      },
      // 4th Row
      {
        name: intl.t('supplier-list.section.empty-state.dummy-data.row-4.supplier'),
        currency: 'EUR',
        accountNumber: intl.t('supplier-list.section.empty-state.dummy-data.row-4.iban'),
        vatRate: 20,
        labels: [
          {
            labelList: {
              name: intl.t('supplier-list.section.empty-state.dummy-data.row-4.custom-label'),
              color: '#FFF4BC',
            },
            name: intl.t('supplier-list.section.empty-state.dummy-data.row-4.custom-label'),
          },
        ],
        hasMany,
      },
    ],
  };
};
