export const DEFAULT_LOCALE_CODE = 'en';

const COUNTRIES_TO_LOCALES_ARRAY = [
  { country_code: 'it', code: 'it' },
  { country_code: 'sm', code: 'it' },
  { country_code: 'dz', code: 'fr' },
  { country_code: 'bj', code: 'fr' },
  { country_code: 'bf', code: 'fr' },
  { country_code: 'bi', code: 'fr' },
  { country_code: 'cf', code: 'fr' },
  { country_code: 'td', code: 'fr' },
  { country_code: 'cd', code: 'fr' },
  { country_code: 'dj', code: 'fr' },
  { country_code: 'fr', code: 'fr' },
  { country_code: 'gf', code: 'fr' },
  { country_code: 'pf', code: 'fr' },
  { country_code: 'tf', code: 'fr' },
  { country_code: 'ga', code: 'fr' },
  { country_code: 'gp', code: 'fr' },
  { country_code: 'gn', code: 'fr' },
  { country_code: 'ci', code: 'fr' },
  { country_code: 'mg', code: 'fr' },
  { country_code: 'ml', code: 'fr' },
  { country_code: 'mq', code: 'fr' },
  { country_code: 'yt', code: 'fr' },
  { country_code: 'mc', code: 'fr' },
  { country_code: 'ma', code: 'fr' },
  { country_code: 'nc', code: 'fr' },
  { country_code: 'ne', code: 'fr' },
  { country_code: 'cg', code: 'fr' },
  { country_code: 're', code: 'fr' },
  { country_code: 'bl', code: 'fr' },
  { country_code: 'mf', code: 'fr' },
  { country_code: 'pm', code: 'fr' },
  { country_code: 'sn', code: 'fr' },
  { country_code: 'tg', code: 'fr' },
  { country_code: 'tn', code: 'fr' },
  { country_code: 'ar', code: 'es' },
  { country_code: 'bo', code: 'es' },
  { country_code: 'cl', code: 'es' },
  { country_code: 'co', code: 'es' },
  { country_code: 'cr', code: 'es' },
  { country_code: 'cu', code: 'es' },
  { country_code: 'do', code: 'es' },
  { country_code: 'ec', code: 'es' },
  { country_code: 'sv', code: 'es' },
  { country_code: 'gq', code: 'es' },
  { country_code: 'gt', code: 'es' },
  { country_code: 'hn', code: 'es' },
  { country_code: 'mx', code: 'es' },
  { country_code: 'ni', code: 'es' },
  { country_code: 'pa', code: 'es' },
  { country_code: 'py', code: 'es' },
  { country_code: 'pe', code: 'es' },
  { country_code: 'es', code: 'es' },
  { country_code: 'uy', code: 'es' },
  { country_code: 've', code: 'es' },
  { country_code: 'at', code: 'de' },
  { country_code: 'de', code: 'de' },
  { country_code: 'li', code: 'de' },
  { country_code: 'af', code: 'en' },
  { country_code: 'ax', code: 'en' },
  { country_code: 'al', code: 'en' },
  { country_code: 'as', code: 'en' },
  { country_code: 'ad', code: 'en' },
  { country_code: 'ao', code: 'en' },
  { country_code: 'ai', code: 'en' },
  { country_code: 'aq', code: 'en' },
  { country_code: 'ag', code: 'en' },
  { country_code: 'am', code: 'en' },
  { country_code: 'aw', code: 'en' },
  { country_code: 'au', code: 'en' },
  { country_code: 'az', code: 'en' },
  { country_code: 'bs', code: 'en' },
  { country_code: 'bh', code: 'en' },
  { country_code: 'bd', code: 'en' },
  { country_code: 'bb', code: 'en' },
  { country_code: 'by', code: 'en' },
  { country_code: 'be', code: 'en' },
  { country_code: 'bz', code: 'en' },
  { country_code: 'bm', code: 'en' },
  { country_code: 'bt', code: 'en' },
  { country_code: 'bq', code: 'en' },
  { country_code: 'ba', code: 'en' },
  { country_code: 'bw', code: 'en' },
  { country_code: 'bv', code: 'en' },
  { country_code: 'br', code: 'en' },
  { country_code: 'ch', code: 'en' },
  { country_code: 'io', code: 'en' },
  { country_code: 'vg', code: 'en' },
  { country_code: 'bn', code: 'en' },
  { country_code: 'bg', code: 'en' },
  { country_code: 'cv', code: 'en' },
  { country_code: 'kh', code: 'en' },
  { country_code: 'cm', code: 'en' },
  { country_code: 'ca', code: 'en' },
  { country_code: 'ky', code: 'en' },
  { country_code: 'cn', code: 'en' },
  { country_code: 'cx', code: 'en' },
  { country_code: 'cc', code: 'en' },
  { country_code: 'km', code: 'en' },
  { country_code: 'ck', code: 'en' },
  { country_code: 'hr', code: 'en' },
  { country_code: 'cw', code: 'en' },
  { country_code: 'cy', code: 'en' },
  { country_code: 'cz', code: 'en' },
  { country_code: 'dk', code: 'en' },
  { country_code: 'dm', code: 'en' },
  { country_code: 'eg', code: 'en' },
  { country_code: 'er', code: 'en' },
  { country_code: 'ee', code: 'en' },
  { country_code: 'sz', code: 'en' },
  { country_code: 'et', code: 'en' },
  { country_code: 'fk', code: 'en' },
  { country_code: 'fo', code: 'en' },
  { country_code: 'fj', code: 'en' },
  { country_code: 'fi', code: 'en' },
  { country_code: 'gm', code: 'en' },
  { country_code: 'ge', code: 'en' },
  { country_code: 'gh', code: 'en' },
  { country_code: 'gi', code: 'en' },
  { country_code: 'gr', code: 'en' },
  { country_code: 'gl', code: 'en' },
  { country_code: 'gd', code: 'en' },
  { country_code: 'gu', code: 'en' },
  { country_code: 'gg', code: 'en' },
  { country_code: 'gw', code: 'en' },
  { country_code: 'gy', code: 'en' },
  { country_code: 'ht', code: 'en' },
  { country_code: 'hm', code: 'en' },
  { country_code: 'hk', code: 'en' },
  { country_code: 'hu', code: 'en' },
  { country_code: 'is', code: 'en' },
  { country_code: 'in', code: 'en' },
  { country_code: 'id', code: 'en' },
  { country_code: 'ir', code: 'en' },
  { country_code: 'iq', code: 'en' },
  { country_code: 'ie', code: 'en' },
  { country_code: 'im', code: 'en' },
  { country_code: 'il', code: 'en' },
  { country_code: 'jm', code: 'en' },
  { country_code: 'jp', code: 'en' },
  { country_code: 'je', code: 'en' },
  { country_code: 'jo', code: 'en' },
  { country_code: 'kz', code: 'en' },
  { country_code: 'ke', code: 'en' },
  { country_code: 'ki', code: 'en' },
  { country_code: 'xk', code: 'en' },
  { country_code: 'kw', code: 'en' },
  { country_code: 'kg', code: 'en' },
  { country_code: 'la', code: 'en' },
  { country_code: 'lv', code: 'en' },
  { country_code: 'lb', code: 'en' },
  { country_code: 'ls', code: 'en' },
  { country_code: 'lr', code: 'en' },
  { country_code: 'ly', code: 'en' },
  { country_code: 'lt', code: 'en' },
  { country_code: 'lu', code: 'en' },
  { country_code: 'mo', code: 'en' },
  { country_code: 'mw', code: 'en' },
  { country_code: 'my', code: 'en' },
  { country_code: 'mv', code: 'en' },
  { country_code: 'mt', code: 'en' },
  { country_code: 'mh', code: 'en' },
  { country_code: 'mr', code: 'en' },
  { country_code: 'mu', code: 'en' },
  { country_code: 'fm', code: 'en' },
  { country_code: 'md', code: 'en' },
  { country_code: 'mn', code: 'en' },
  { country_code: 'me', code: 'en' },
  { country_code: 'ms', code: 'en' },
  { country_code: 'mz', code: 'en' },
  { country_code: 'mm', code: 'en' },
  { country_code: 'na', code: 'en' },
  { country_code: 'nr', code: 'en' },
  { country_code: 'np', code: 'en' },
  { country_code: 'nl', code: 'en' },
  { country_code: 'an', code: 'en' },
  { country_code: 'nz', code: 'en' },
  { country_code: 'ng', code: 'en' },
  { country_code: 'nu', code: 'en' },
  { country_code: 'nf', code: 'en' },
  { country_code: 'kp', code: 'en' },
  { country_code: 'mk', code: 'en' },
  { country_code: 'mp', code: 'en' },
  { country_code: 'no', code: 'en' },
  { country_code: 'om', code: 'en' },
  { country_code: 'pk', code: 'en' },
  { country_code: 'pw', code: 'en' },
  { country_code: 'ps', code: 'en' },
  { country_code: 'pg', code: 'en' },
  { country_code: 'ph', code: 'en' },
  { country_code: 'pn', code: 'en' },
  { country_code: 'pl', code: 'en' },
  { country_code: 'pt', code: 'en' },
  { country_code: 'pr', code: 'en' },
  { country_code: 'qa', code: 'en' },
  { country_code: 'ro', code: 'en' },
  { country_code: 'ru', code: 'en' },
  { country_code: 'rw', code: 'en' },
  { country_code: 'sh', code: 'en' },
  { country_code: 'kn', code: 'en' },
  { country_code: 'lc', code: 'en' },
  { country_code: 'vc', code: 'en' },
  { country_code: 'ws', code: 'en' },
  { country_code: 'st', code: 'en' },
  { country_code: 'sa', code: 'en' },
  { country_code: 'rs', code: 'en' },
  { country_code: 'cs', code: 'en' },
  { country_code: 'sc', code: 'en' },
  { country_code: 'sl', code: 'en' },
  { country_code: 'sg', code: 'en' },
  { country_code: 'sx', code: 'en' },
  { country_code: 'sk', code: 'en' },
  { country_code: 'si', code: 'en' },
  { country_code: 'sb', code: 'en' },
  { country_code: 'so', code: 'en' },
  { country_code: 'za', code: 'en' },
  { country_code: 'gs', code: 'en' },
  { country_code: 'kr', code: 'en' },
  { country_code: 'ss', code: 'en' },
  { country_code: 'lk', code: 'en' },
  { country_code: 'sd', code: 'en' },
  { country_code: 'sr', code: 'en' },
  { country_code: 'sj', code: 'en' },
  { country_code: 'se', code: 'en' },
  { country_code: 'sy', code: 'en' },
  { country_code: 'tw', code: 'en' },
  { country_code: 'tj', code: 'en' },
  { country_code: 'tz', code: 'en' },
  { country_code: 'th', code: 'en' },
  { country_code: 'tl', code: 'en' },
  { country_code: 'tk', code: 'en' },
  { country_code: 'to', code: 'en' },
  { country_code: 'tt', code: 'en' },
  { country_code: 'tr', code: 'en' },
  { country_code: 'tm', code: 'en' },
  { country_code: 'tc', code: 'en' },
  { country_code: 'tv', code: 'en' },
  { country_code: 'vi', code: 'en' },
  { country_code: 'ug', code: 'en' },
  { country_code: 'ua', code: 'en' },
  { country_code: 'ae', code: 'en' },
  { country_code: 'gb', code: 'en' },
  { country_code: 'us', code: 'en' },
  { country_code: 'um', code: 'en' },
  { country_code: 'uz', code: 'en' },
  { country_code: 'vu', code: 'en' },
  { country_code: 'va', code: 'en' },
  { country_code: 'vn', code: 'en' },
  { country_code: 'wf', code: 'en' },
  { country_code: 'eh', code: 'en' },
  { country_code: 'ye', code: 'en' },
  { country_code: 'zm', code: 'en' },
  { country_code: 'zw', code: 'en' },
];

export default COUNTRIES_TO_LOCALES_ARRAY;
