export const SUBSCRIPTION_RECURRENCES = {
  ANNUAL: 'annual',
  MONTHLY: 'monthly',
} as const;

export const TRACKING_TRIAL_STATE = {
  NONE: 'none',
  INITIAL_TRIAL: 'initial_trial',
  FREE_TRIAL: 'free_trial',
} as const;

export const SUBSCRIPTION_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  PENDING_TERMINATION: 'pending_termination',
} as const;

export const BENEFITS_LIST_WARNINGS = {
  ADVERTISING_CARDS_DISALLOWED: 'advertising_cards_disallowed',
  FEATURE_BOOKKEEPING_DISABLED: 'feature_bookkeeping_disabled',
  FLASH_CARDS_DISALLOWED: 'flash_cards_disallowed',
  MANAGER_ROLE_DISALLOWED: 'manager_role_disallowed',
  INCLUDED_TRANSFERS_DECREASE: 'included_transfers_decrease',
  INCLUDED_CHECKS_DECREASE: 'included_checks_decrease',
  FEATURE_REQUESTS_DISABLED: 'feature_requests_disabled',
  FEATURE_SUPPLIER_INVOICES_PENDING_DISABLED: 'feature_supplier_invoices_pending_disabled',
  CONNECT_EBICS_INTEGRATIONS_DISABLED: 'connect_ebics_integrations_disabled',
  CONNECT_PLUS_INTEGRATIONS_DISABLED: 'connect_plus_integrations_disabled',
  UNLIMITED_USERS_DISALLOWED: 'unlimited_users_disallowed',
  FREE_USERS_DISALLOWED: 'free_users_disallowed',
} as const;

export const LIMITS = {
  HARD: 'hard',
  SOFT: 'soft',
} as const;

export const SUBSCRIPTION_PRODUCT_TYPES = {
  ADDON: 'addon',
  PLAN: 'core',
} as const;
