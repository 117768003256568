import { BADGE_TYPE } from '@qonto/ui-kit/constants/badge-types';

import { CARD_DESIGNS, CARD_STATUSES_COLORS } from 'qonto/constants/cards';
import {
  CODES,
  getTrackingNameAndProperties,
  LAYOUT,
  TYPES,
} from 'qonto/constants/empty-states/system';

const TAB_NAMES = {
  ARCHIVED: 'archived',
  MY_CARDS: 'my-cards',
  REQUESTS: {
    APPROVER: 'tasks',
    REQUESTER: 'requests',
  },
  TEAM_CARDS: 'team',
};

const EMPTY_LOTTIE_ARCHIVED = '/lotties/empty-state/no-cards-archived.json';
const EMPTY_LOTTIE_NO_CARDS = '/lotties/empty-state/no-cards.json';
const EMPTY_LOTTIE_SEARCH = '/lotties/empty-state/search-no-result.json';
const EMPTY_LOTTIE_TASKS = '/lotties/empty-state/no-tasks.json';

const name = 'cards';
export const getEmptyStateConfig = intl => {
  let dummyData = getDummyData(intl, true);
  let dummyData2 = getDummyData(intl);

  return {
    name,
    featureName: ['physicalCard', 'virtualCard'],
    variations: {
      ES_A3: {
        code: CODES.ES_A3,
        layout: LAYOUT.DISCOVER_PREVIEW,
        tracking: getTrackingNameAndProperties({ name, type: TYPES.ACTIVATE }),
        badgeType: BADGE_TYPE.ACTIVATE,
        badgeText: intl.t('empty-states.system.activate.a3.badge'),
        variations: [
          {
            inputs: {
              activateAbilities: ['canCreateCards', 'hasTeamManagementFeature'],
            },
            output: {
              title: intl.t('cards.empty-state.a3.create-card.team.title'),
              subtitle: intl.t('cards.empty-state.a3.create-card.team.subtitle'),
              dummyData,
            },
          },
          {
            inputs: {
              activateAbilities: ['canCreateCards'],
            },
            output: {
              title: intl.t('cards.empty-state.a3.create-card.no-team.title'),
              subtitle: intl.t('cards.empty-state.a3.create-card.no-team.subtitle'),
              dummyData,
            },
          },
          {
            inputs: {
              activateAbilities: ['canRequestCards'],
            },
            output: {
              title: intl.t('cards.empty-state.a3.request-card.team.title'),
              subtitle: intl.t('cards.empty-state.a3.request-card.team.subtitle'),
              dummyData: dummyData2,
            },
          },
          {
            inputs: {
              copyAbilities: ['canReadCards'],
            },
            output: {
              code: CODES.ES_I3,
              dummyData: dummyData2,
              tracking: getTrackingNameAndProperties({ name, type: TYPES.INFORM }),
              title: intl.t('cards.empty-state.i3.no-request-card.team.title'),
              subtitle: intl.t('cards.empty-state.i3.no-request-card.team.subtitle'),
            },
          },
        ],
      },
      ES_I1: {
        code: CODES.ES_I1,
        layout: LAYOUT.INFORM,
        tracking: getTrackingNameAndProperties({ name, type: TYPES.INFORM }),
        variations: [
          {
            inputs: {
              activateAbilities: ['canCreateCards'],
              customInputs: {
                tab: TAB_NAMES.MY_CARDS,
                isSubAccountClosed: false,
              },
            },
            output: {
              title: intl.t('cards.empty-state.i1.my-cards.title'),
              subtitle: intl.t('cards.empty-state.i1.create-card.my-cards.subtitle'),
              lottieSrc: EMPTY_LOTTIE_NO_CARDS,
            },
          },
          {
            inputs: {
              activateAbilities: ['canRequestCards'],
              customInputs: {
                tab: TAB_NAMES.MY_CARDS,
                isSubAccountClosed: false,
              },
            },
            output: {
              title: intl.t('cards.empty-state.i1.my-cards.title'),
              subtitle: intl.t('cards.empty-state.i1.request-card.my-cards.subtitle'),
              lottieSrc: EMPTY_LOTTIE_NO_CARDS,
            },
          },
          {
            inputs: {
              activateAbilities: ['canReadCards'],
              customInputs: {
                tab: TAB_NAMES.MY_CARDS,
                isSubAccountClosed: false,
              },
            },
            output: {
              title: intl.t('cards.empty-state.i1.my-cards.title'),
              subtitle: intl.t('cards.empty-state.i1.no-request-card.my-cards.subtitle'),
              lottieSrc: EMPTY_LOTTIE_NO_CARDS,
            },
          },
          {
            inputs: {
              activateAbilities: ['canCreateCards'],
              customInputs: {
                tab: TAB_NAMES.TEAM_CARDS,
                isSubAccountClosed: false,
              },
            },
            output: {
              title: intl.t('cards.empty-state.i1.team-cards.title'),
              subtitle: intl.t('cards.empty-state.i1.create-card.team-cards.title'),
              lottieSrc: EMPTY_LOTTIE_NO_CARDS,
            },
          },
          {
            inputs: {
              activateAbilities: ['canRequestCards'],
              customInputs: {
                tab: TAB_NAMES.TEAM_CARDS,
                isSubAccountClosed: false,
              },
            },
            output: {
              title: intl.t('cards.empty-state.i1.team-cards.title'),
              subtitle: intl.t('cards.empty-state.i1.request-card.team-cards.title'),
              lottieSrc: EMPTY_LOTTIE_NO_CARDS,
            },
          },
          {
            inputs: {
              customInputs: {
                tab: TAB_NAMES.ARCHIVED,
                isSubAccountClosed: false,
              },
            },
            output: {
              title: intl.t('cards.empty-state.i1.archived.title'),
              subtitle: intl.t('cards.empty-state.i1.archived.subtitle'),
              lottieSrc: EMPTY_LOTTIE_ARCHIVED,
            },
          },
          {
            inputs: {
              customInputs: {
                tab: TAB_NAMES.REQUESTS.REQUESTER,
                isSubAccountClosed: false,
              },
            },
            output: {
              title: intl.t('cards.empty-state.i1.requests.title'),
              subtitle: intl.t('cards.empty-state.i1.requests.subtitle'),
              lottieSrc: EMPTY_LOTTIE_TASKS,
            },
          },
          {
            inputs: {
              customInputs: {
                tab: TAB_NAMES.REQUESTS.APPROVER,
                isSubAccountClosed: false,
              },
            },
            output: {
              title: intl.t('cards.empty-state.i1.tasks.title'),
              subtitle: intl.t('cards.empty-state.i1.tasks.subtitle'),
              lottieSrc: EMPTY_LOTTIE_TASKS,
            },
          },
          {
            inputs: {
              copyAbilities: ['canReadBankAccount', 'hasMultiAccountsFeature'],
              customInputs: {
                isSubAccountClosed: true,
              },
            },
            output: {
              title: intl.t('cards.empty-state.i1.closed-account.title'),
              subtitle: intl.t('cards.empty-state.i1.closed-account.subtitle'),
              lottieSrc: EMPTY_LOTTIE_NO_CARDS,
            },
          },
        ],
      },
      ES_I2: {
        code: CODES.ES_I2,
        layout: LAYOUT.INFORM,
        lottieSrc: EMPTY_LOTTIE_SEARCH,
        tracking: getTrackingNameAndProperties({ name, type: TYPES.INFORM }),
        variations: [
          {
            output: {
              title: intl.t('empty-states.system.inform.i2.title'),
              subtitle: intl.t('empty-states.system.inform.i2.subtitle'),
            },
          },
        ],
      },
      ES_I3: {
        code: CODES.ES_I3,
        layout: LAYOUT.DISCOVER_PREVIEW,
        badgeText: intl.t('empty-states.system.activate.a3.badge'),
        badgeType: BADGE_TYPE.ACTIVATE,
        dummyData: dummyData2,
        tracking: getTrackingNameAndProperties({ name, type: TYPES.INFORM }),
        variations: [
          {
            inputs: {
              copyAbilities: ['canReadCards'],
            },
            output: {
              title: intl.t('cards.empty-state.i3.no-request-card.team.title'),
              subtitle: intl.t('cards.empty-state.i3.no-request-card.team.subtitle'),
            },
          },
        ],
      },
    },
  };
};

export const getDummyData = (intl, canCreateCard) => {
  let items;
  let commonData = {
    displayedStatus: intl.t('cards.status.live'),
    statusColor: CARD_STATUSES_COLORS.live,
    holder: {
      fullName: intl.t('empty-states.system.dummy-data.name-11'),
    },
  };

  if (canCreateCard) {
    items = [
      {
        ...commonData,
        id: '1',
        paymentMonthlyLimit: 20000,
        paymentMonthlySpent: 10000,
        last4: '2313',
        cardDesign: CARD_DESIGNS.FLASH_DEFAULT_2021,
        nickname: intl.t('cards.name.flash'),
      },
      {
        ...commonData,
        id: '2',
        paymentMonthlyLimit: 200000,
        paymentMonthlySpent: 40000,
        last4: '9137',
        cardDesign: CARD_DESIGNS.PLUS_BLACK_2023,
        nickname: intl.t('cards.name.metal'),
      },
    ];
  } else {
    items = [
      {
        ...commonData,
        id: '1',
        paymentMonthlyLimit: 5000,
        paymentMonthlySpent: 2500,
        last4: '2313',
        cardDesign: CARD_DESIGNS.FLASH_DEFAULT_2021,
        nickname: intl.t('cards.name.flash'),
      },
      {
        ...commonData,
        id: '2',
        paymentMonthlyLimit: 1000,
        paymentMonthlySpent: 200,
        last4: '9137',
        cardDesign: CARD_DESIGNS.VIRTUAL_DEFAULT_2017,
        nickname: intl.t('cards.name.virtual'),
      },
    ];
  }
  return {
    activeTab: TAB_NAMES.MY_CARDS,
    items,
  };
};
