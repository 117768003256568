export const USER_ACTIONS_STATUS = {
  ENABLED: 'enabled',
  DISMISSED: 'dismissed',
};

export const USER_ACTIONS_TYPE = {
  SETUP: 'setup',
  REQUIRED: 'required',
  DISCOVERY: 'discovery',
};

export const USER_ACTIONS_CTA_TYPE = {
  LINK: 'LINK',
  LINK_TO: 'LINK_TO',
  LINK_TO_FLOW: 'LINK_TO_FLOW',
  BUTTON: 'BUTTON',
};

export const USER_ACTIONS_ILLUSTRATION_TYPE = {
  SVG: 'SVG',
  LOTTIE: 'LOTTIE',
  REMOTE_IMAGE: 'REMOTE_IMAGE',
  REMOTE_LOTTIE: 'REMOTE_LOTTIE',
};

export const USER_ACTIONS_CTA_BUTTON_TYPE = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
};

export const USER_ACTIONS_CTA_BUTTON_TYPE_MAP = {
  [USER_ACTIONS_CTA_BUTTON_TYPE.PRIMARY]: 'btn--primary',
  [USER_ACTIONS_CTA_BUTTON_TYPE.SECONDARY]: 'btn--secondary',
  [USER_ACTIONS_CTA_BUTTON_TYPE.TERTIARY]: 'btn--tertiary',
};
