export const CERTIFICATION_DURATION_DAYS = 42;
export const LOW_MEDIUM_RISK_DUE_DILIGENCE_WEEKS = 8;
export const HIGH_RISK_DUE_DILIGENCE_WEEKS = 4;
export const QONTO_VERIFICATION_URL = 'https://verification.qonto.com/kyc';
export const COMPLETE_QONTO_VERIFICATION_URL_REGEX = new RegExp(
  'https://verification\\.qonto\\.com/kyc/[0-9]+\\?locale=[\\w-]+&qonto_redirection_url=.+'
);

export const COMPANY_REPORT_STATUS = {
  NOT_FOUND: 'not_found',
  FOUND: 'found',
  UNKNOWN: 'unknown',
};

export const IS_PERIODIC_WARNING_REVIEWED = 'isPeriodicWarningReviewed';

export const RISK_CLASS = {
  LOW: 'low',
  MEDIUM: 'medium',
  HIGH: 'high',
  BANNED: 'banned',
} as const;
