export const THEME = {
  DARK: 'dark',
  LIGHT: 'light',
};

export const APPEARANCE_KEY = 'APPEARANCE';

export default {
  DARK: 'dark',
  LIGHT: 'light',
  SYSTEM: 'system',
};
