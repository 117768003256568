export const ACTIVITY_TAGS = {
  ATM: 'atm',
  FEES: 'fees',
  FINANCE: 'finance',
  FOOD_AND_GROCERY: 'food_and_grocery',
  GAS_STATION: 'gas_station',
  HARDWARE_AND_EQUIPMENT: 'hardware_and_equipment',
  HOTEL_AND_LODGING: 'hotel_and_lodging',
  INSURANCE: 'insurance',
  IT_AND_ELECTRONICS: 'it_and_electronics',
  LEGAL_AND_ACCOUNTING: 'legal_and_accounting',
  LOGISTICS: 'logistics',
  MANUFACTURING: 'manufacturing',
  MARKETING: 'marketing',
  OFFICE_RENTAL: 'office_rental',
  OFFICE_SUPPLY: 'office_supply',
  ONLINE_SERVICE: 'online_service',
  OTHER_EXPENSE: 'other_expense',
  OTHER_INCOME: 'other_income',
  OTHER_SERVICE: 'other_service',
  REFUND: 'refund',
  RESTAURANT_AND_BAR: 'restaurant_and_bar',
  SALARY: 'salary',
  SALES: 'sales',
  SUBSCRIPTION: 'subscription',
  TAX: 'tax',
  TRANSPORT: 'transport',
  TREASURY_AND_INTERCO: 'treasury_and_interco',
  UTILITY: 'utility',
  VOUCHER: 'voucher',
  DIRECT_DEBIT_HOLD: 'direct_debit_hold',
};

export const {
  ATM,
  FEES,
  FINANCE,
  FOOD_AND_GROCERY,
  GAS_STATION,
  HARDWARE_AND_EQUIPMENT,
  HOTEL_AND_LODGING,
  INSURANCE,
  IT_AND_ELECTRONICS,
  LEGAL_AND_ACCOUNTING,
  LOGISTICS,
  MANUFACTURING,
  MARKETING,
  OFFICE_RENTAL,
  OFFICE_SUPPLY,
  ONLINE_SERVICE,
  OTHER_EXPENSE,
  OTHER_INCOME,
  OTHER_SERVICE,
  REFUND,
  RESTAURANT_AND_BAR,
  SALARY,
  SALES,
  SUBSCRIPTION,
  TAX,
  TRANSPORT,
  TREASURY_AND_INTERCO,
  UTILITY,
  VOUCHER,
  DIRECT_DEBIT_HOLD,
} = ACTIVITY_TAGS;

const CATEGORIES = {
  codes: [
    ATM,
    FEES,
    FINANCE,
    FOOD_AND_GROCERY,
    GAS_STATION,
    HARDWARE_AND_EQUIPMENT,
    HOTEL_AND_LODGING,
    INSURANCE,
    IT_AND_ELECTRONICS,
    LEGAL_AND_ACCOUNTING,
    LOGISTICS,
    MANUFACTURING,
    MARKETING,
    OFFICE_RENTAL,
    OFFICE_SUPPLY,
    ONLINE_SERVICE,
    OTHER_EXPENSE,
    OTHER_INCOME,
    OTHER_SERVICE,
    REFUND,
    RESTAURANT_AND_BAR,
    SALARY,
    SALES,
    SUBSCRIPTION,
    TAX,
    TRANSPORT,
    TREASURY_AND_INTERCO,
    UTILITY,
    VOUCHER,
  ],
  Card: [
    ATM,
    FINANCE,
    FOOD_AND_GROCERY,
    GAS_STATION,
    HARDWARE_AND_EQUIPMENT,
    HOTEL_AND_LODGING,
    INSURANCE,
    IT_AND_ELECTRONICS,
    LEGAL_AND_ACCOUNTING,
    LOGISTICS,
    MANUFACTURING,
    MARKETING,
    OFFICE_RENTAL,
    OFFICE_SUPPLY,
    ONLINE_SERVICE,
    OTHER_EXPENSE,
    OTHER_SERVICE,
    REFUND,
    RESTAURANT_AND_BAR,
    SALARY,
    TAX,
    TRANSPORT,
    UTILITY,
  ],
  DirectDebit: [
    FINANCE,
    FOOD_AND_GROCERY,
    GAS_STATION,
    HARDWARE_AND_EQUIPMENT,
    HOTEL_AND_LODGING,
    INSURANCE,
    IT_AND_ELECTRONICS,
    LEGAL_AND_ACCOUNTING,
    LOGISTICS,
    MANUFACTURING,
    MARKETING,
    OFFICE_RENTAL,
    OFFICE_SUPPLY,
    ONLINE_SERVICE,
    OTHER_EXPENSE,
    OTHER_SERVICE,
    REFUND,
    RESTAURANT_AND_BAR,
    SALARY,
    TAX,
    TRANSPORT,
    TREASURY_AND_INTERCO,
    UTILITY,
  ],
  Income: [OTHER_INCOME, REFUND, SALES, TREASURY_AND_INTERCO],
  FinancingIncome: [OTHER_INCOME],
  Check: [OTHER_INCOME, REFUND, SALES, TREASURY_AND_INTERCO],
  Other: [
    FINANCE,
    FOOD_AND_GROCERY,
    GAS_STATION,
    HARDWARE_AND_EQUIPMENT,
    HOTEL_AND_LODGING,
    INSURANCE,
    IT_AND_ELECTRONICS,
    LEGAL_AND_ACCOUNTING,
    LOGISTICS,
    MANUFACTURING,
    MARKETING,
    OFFICE_RENTAL,
    OFFICE_SUPPLY,
    ONLINE_SERVICE,
    OTHER_EXPENSE,
    OTHER_SERVICE,
    RESTAURANT_AND_BAR,
    SALARY,
    TAX,
    TRANSPORT,
    TREASURY_AND_INTERCO,
    UTILITY,
  ],
  Transfer: [
    FINANCE,
    FOOD_AND_GROCERY,
    GAS_STATION,
    HARDWARE_AND_EQUIPMENT,
    HOTEL_AND_LODGING,
    INSURANCE,
    IT_AND_ELECTRONICS,
    LEGAL_AND_ACCOUNTING,
    LOGISTICS,
    MANUFACTURING,
    MARKETING,
    OFFICE_RENTAL,
    OFFICE_SUPPLY,
    ONLINE_SERVICE,
    OTHER_EXPENSE,
    OTHER_SERVICE,
    RESTAURANT_AND_BAR,
    SALARY,
    TAX,
    TRANSPORT,
    TREASURY_AND_INTERCO,
    UTILITY,
  ],
  PagopaPayment: [OTHER_EXPENSE, OTHER_SERVICE, TAX, UTILITY],
  DirectDebitCollection: [OTHER_INCOME, REFUND, SALES, TREASURY_AND_INTERCO],
  DirectDebitCollectionRTransaction: [
    FINANCE,
    FOOD_AND_GROCERY,
    GAS_STATION,
    HARDWARE_AND_EQUIPMENT,
    HOTEL_AND_LODGING,
    INSURANCE,
    IT_AND_ELECTRONICS,
    LEGAL_AND_ACCOUNTING,
    LOGISTICS,
    MANUFACTURING,
    MARKETING,
    OFFICE_RENTAL,
    OFFICE_SUPPLY,
    ONLINE_SERVICE,
    OTHER_EXPENSE,
    OTHER_SERVICE,
    RESTAURANT_AND_BAR,
    SALARY,
    TAX,
    TRANSPORT,
    TREASURY_AND_INTERCO,
    UTILITY,
  ],
  DirectDebitHold: [DIRECT_DEBIT_HOLD],
};

export default CATEGORIES;
